import localforage from 'localforage';

// todo: remove specifying this later. Just doing to avoid any possible issue
localforage.setDriver(localforage.LOCALSTORAGE);

export default localforage;

// todo: allow an agent to resume looking at an account within 3 minutes of the last time it was viewed (set
// todo: localstorage on beforeUnload & maybe once a minute too in case beforeUnload fails)
// key: account
export type ApplicantData = {
  requestId: string;
  timestamp: number;
};

// key: device
export type DeviceData = string;

// key: agent
export type AgentData = {
  email: string;
};
