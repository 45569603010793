import { Address } from '@trellisconnect/types';

export function formatAddress(address: Address | undefined | null): string | undefined {
  if (!address) {
    return undefined;
  }

  const { prefix, number, street, type, suffix, sec_unit_type, sec_unit_num, city, state, zip, plus4, raw } = address;

  const cityWithComma = city ? `${city},` : '';
  const zipWithPlus4 = zip && plus4 ? `${zip}-${plus4}` : zip;

  const addressText = [
    prefix,
    number,
    street,
    type,
    suffix,
    sec_unit_type,
    sec_unit_num,
    cityWithComma,
    state,
    zipWithPlus4,
    raw,
  ]
    .filter(Boolean)
    .join(' ');

  return addressText;
}
