import * as React from 'react';

import localDb, { AgentData } from '../lib/localDb';

import Button from './Button';
import Card from './Card';
import { Store } from '../modules/rootReducer';
import { connect } from 'react-redux';
import { dispatch } from '../store';
import isEmail from 'validator/lib/isEmail';
import { updateAgentData } from '../modules/agent';

interface EmailModalProps {
  deviceId: string;
  isVisible: boolean;
}

function EmailModal({ deviceId, isVisible = false }: EmailModalProps) {
  const [email, setEmail] = React.useState('');
  const isDisabled = !isEmail(email);

  function onSubmit() {
    analytics.identify(deviceId, { email });

    // Store email in local storage
    try {
      const agentData: AgentData = {
        email,
      };
      localDb.setItem('agent', agentData);
    } catch (e) {
      console.log('Unable to SET agent data to local storage');
    }

    dispatch(updateAgentData({ email }));
  }

  function onEmailChange(e: React.FormEvent<HTMLInputElement>) {
    setEmail(e.currentTarget.value);
  }

  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-30 flex items-center justify-center">
      <Card className="flex items-center flex-col max-w-lg rounded">
        <header className="mb-6 text-xl text-center text-[#333333]">
          <div>To access Trellis Agent,</div>
          <div>
            please enter your <strong>work</strong> email address
          </div>
        </header>

        <input
          type="text"
          onChange={onEmailChange}
          value={email}
          placeholder="Enter Your Email Address"
          className="mb-6 w-full border border-gray-100 rounded-full text-center py-4"
        />

        <Button className="w-full" onClick={onSubmit} disabled={isDisabled}>
          Continue
        </Button>
      </Card>
    </div>
  );
}

function mapStateToProps(state: Store) {
  return {
    deviceId: state.agent.data.deviceId,
    isVisible: !state.agent.data.email,
  };
}

export default connect(mapStateToProps)(EmailModal);
