export function checkUnknownValues(val: boolean | null | undefined): string | null {
  switch (val) {
    case true:
      return 'Yes';
    case false:
      return 'No';
    default:
      return null;
  }
}
