import * as React from 'react';

import { throttle } from '../utils/helpers';

function scrollToTop() {
  window.scroll(0, 0);
}

export default function ScrollToTopButton() {
  const [isVisible, setIsVisible] = React.useState(false);

  function handleScroll() {
    const scrollHeight =
      window.scrollY || window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    setIsVisible(scrollHeight > 100);
  }

  const determineVisibility = throttle(handleScroll, 250);

  React.useEffect(() => {
    window.addEventListener('scroll', determineVisibility);

    return () => {
      window.removeEventListener('scroll', determineVisibility);
    };
  }, [determineVisibility]);

  if (!isVisible) return null;

  return (
    <button
      className="bg-trellis-blue rounded-full w-12 h-12 fixed right-5 top-5 flex items-center justify-center text-white shadow-sm"
      onClick={scrollToTop}
    >
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="arrow-up"
        width="1.2em"
        height="1.2em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M868 545.5L536.1 163a31.96 31.96 0 0 0-48.3 0L156 545.5a7.97 7.97 0 0 0 6 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path>
      </svg>
    </button>
  );
}
