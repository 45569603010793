import * as React from 'react';

import { CardItem, Issuer, PropertyPolicy } from '../../utils/types';
import { RESIDENCE_STATUS, propertyCoverageMapping } from '../../utils/mappings';
import { StorePolicies, getPolicyTypeReadable } from '../../modules/policies/redux';
import { centsToCurrency, dateFormat, isPolicyExpired, isPolicyFuture } from '../../lib/util';
import { checkIfLastDayOfMonth, checkUnknownValues, formatRecentPayment } from '../../utils';
import { formatPropertyCoverage, sortCoveragesByLabel } from '../../utils/coverages';

import AlertBox from '../AlertBox';
import CopyText from '../copyText';
import SectionHeader from '../SectionHeader';
import { StoreIssuers } from '../../modules/issuers/redux';
import StructuredCard from '../StructuredCard';
import { capitalize } from '../../utils/helpers';
import { connect } from 'react-redux';

interface PropertyPolicyDetailProps extends React.ComponentProps<'section'> {
  selectedPolicy: PropertyPolicy | null;
  issuers: Map<string, Issuer>;
}

const futurePolicyAlert = (
  <span>
    <strong>This is a future policy</strong> - some coverage limits may be missing because they were unavailable when
    this customer last linked their account.
  </span>
);

const wasFuturePolicyAlert = (
  <span>
    Some coverage limits may be missing because they were unavailable when this customer last linked their account.
  </span>
);

const expiredPolicyAlert = (
  <span>
    <strong>This policy is expired</strong>
  </span>
);

const getPolicyAlert = (policy: PropertyPolicy): JSX.Element | null => {
  if (isPolicyExpired(policy)) return expiredPolicyAlert;
  if (isPolicyFuture(policy)) return futurePolicyAlert;

  const dateRetrieved = new Date(policy.dateRetrieved as string);
  const wasFuturePolicy = isPolicyFuture(policy, dateFormat(dateRetrieved));

  if (wasFuturePolicy) return wasFuturePolicyAlert;

  return null;
};

function getPolicyItems(policy: PropertyPolicy, issuers: Map<string, Issuer>): CardItem[] {
  const policyItems: CardItem[] = [];
  const { canceledDate, issuer, policyNumber } = policy;

  if (issuer) {
    const issuerObj = issuers.get(issuer);
    policyItems.push({
      label: 'Policy Issuer',
      value: issuerObj ? issuerObj.name : capitalize(issuer) || null,
    });
  }

  policyItems.push({
    label: 'Policy Type',
    value: getPolicyTypeReadable(policy?.policyType),
  });

  policyItems.push({
    label: 'Policy Number',
    value: (
      <p className="text-sm">
        {policyNumber}
        <CopyText copyValue={policyNumber} notificationLabel="Policy Number" />
      </p>
    ),
  });

  // Get policy term
  const policyTerm = formatPolicyTerm(policy);
  if (policyTerm.value) {
    policyItems.push(policyTerm);
  }

  const policyTermMonths = policy?.policyTermMonths;
  const termLengthValue = policyTermMonths ? `${policyTermMonths} month${policyTermMonths > 1 ? 's' : ''}` : '';

  const termLengthItem = {
    label: 'Term Length',
    value: termLengthValue,
  };

  if (termLengthValue) {
    policyItems.push(termLengthItem);
  }

  if (canceledDate) {
    const [year, month, day] = canceledDate.split('-').map(Number);
    if (year && month && day) {
      const canceledDate = new Date(year, month - 1, day);
      const formattedCanceledDate = `${canceledDate.toLocaleString('en-US', {
        month: 'short',
      })} ${canceledDate.getDate()}, ${canceledDate.getFullYear()}`;
      policyItems.push({
        label: 'Canceled Date',
        value: formattedCanceledDate,
      });
    }
  }

  const premiumCents = policy?.premiumCents;

  if (premiumCents) {
    const totalPremium = centsToCurrency(premiumCents, false);
    const monthlyAverage = policyTermMonths
      ? centsToCurrency(premiumCents > 0 ? premiumCents / policyTermMonths : 0, false)
      : 0;

    const totalPremiumValue = `${totalPremium}${monthlyAverage ? ` (${monthlyAverage} avg/mo)` : ''}`;

    policyItems.push({
      label: 'Total Premium',
      value: totalPremiumValue,
    });
  }

  const paymentScheduleMonths = policy?.paymentScheduleMonths;
  if (paymentScheduleMonths) {
    policyItems.push({
      label: 'Payment Frequency',
      value: `Every ${paymentScheduleMonths} month${paymentScheduleMonths > 1 ? 's' : ''}`,
    });
  }

  const paymentHistory = policy?.paymentHistory;
  if (paymentHistory?.length) {
    const firstPayment = paymentHistory.find(payment => payment !== undefined);
    policyItems.push({
      label: 'Most Recent Payment',
      value: firstPayment ? formatRecentPayment(firstPayment) : 'Unknown',
    });
  }

  const policyHolder = policy?.policyHolder;
  if (policyHolder) {
    const isHomeOwner = checkUnknownValues(policyHolder.isHomeOwner);
    const residenceStatus = policyHolder?.residenceStatus;
    const tenureInYears = getTenure(policy);

    if (isHomeOwner) {
      policyItems.push({
        label: 'Homeowner',
        value: isHomeOwner,
      });
    }

    if (residenceStatus) {
      policyItems.push({
        label: 'Residence Status',
        value: RESIDENCE_STATUS[residenceStatus] || residenceStatus,
      });
    }

    if (tenureInYears) {
      policyItems.push({
        label: 'Tenure In Years',
        value: tenureInYears,
      });
    }
  }

  policyItems.push({
    label: 'Policy Is Active',
    value: <p className="text-sm">{policy?.active === true ? 'Yes' : policy?.active === false ? 'No' : 'Unknown'}</p>,
  });

  return policyItems;
}

function formatPolicyTerm(policy: PropertyPolicy): CardItem {
  let formattedTerm = '';
  const { issueDate } = policy;

  if (issueDate) {
    const [year, month, day] = issueDate.split('-').map(Number);
    if (year && month && day) {
      const startDate = new Date(year, month - 1, day);
      const formattedStartDate = `${startDate.toLocaleString('en-US', {
        month: 'short',
      })} ${startDate.getDate()}, ${startDate.getFullYear()}`;
      let endDate;
      const policyTermMonths = policy?.policyTermMonths;

      if (policy.renewalDate) {
        const splitExpDate = policy.renewalDate.split('-');
        endDate = new Date(Number(splitExpDate[0]), Number(splitExpDate[1]) - 1, Number(splitExpDate[2]));
      } else if (policyTermMonths) {
        const isLastDayOfMonth = checkIfLastDayOfMonth(year, month, day);
        const dayOfMonth = isLastDayOfMonth ? 0 : day;
        const termMonth = month + policyTermMonths - (isLastDayOfMonth ? 0 : 1);

        if (termMonth && dayOfMonth) {
          endDate = new Date(year, termMonth, dayOfMonth);
        }
      }

      if (endDate) {
        const formattedEndDate = `${endDate.toLocaleString('en-US', {
          month: 'short',
        })} ${endDate.getDate()}, ${endDate.getFullYear()}`;

        if (formattedStartDate && formattedEndDate) {
          formattedTerm = `${formattedStartDate} - ${formattedEndDate}`;
        }
      } else if (formattedStartDate) {
        formattedTerm = `${formattedStartDate} - Unknown`;
      }
    }
  }

  return {
    label: 'Term',
    value: formattedTerm,
  };
}

function getTenure(policy: PropertyPolicy): string | null {
  const tenureInYears = policy?.policyHolder?.tenureInYears;
  return tenureInYears ? tenureInYears.toString() : null;
}

function getCoverageItems(policy: PropertyPolicy) {
  return getPropertyCoverageItems(policy as PropertyPolicy);
}

function getPropertyCoverageItems(policy: PropertyPolicy): CardItem[] {
  const coverageMap: { [name: string]: CardItem } = {};
  const coverages = policy?.coverages;
  if (coverages?.length) {
    for (const cov of coverages) {
      if (cov.name) {
        const coverageIndex = propertyCoverageMapping[cov.name] ? propertyCoverageMapping[cov.name] : cov.name;
        if (coverageIndex) {
          coverageMap[coverageIndex] = {
            label: propertyCoverageMapping[cov.name] ? propertyCoverageMapping[cov.name] : cov.name,
            value: formatPropertyCoverage(cov),
          } as CardItem;
        }
      }
    }
  }

  return Object.values(coverageMap).sort(sortCoveragesByLabel);
}

function PropertyPolicyDetails({ selectedPolicy, issuers }: PropertyPolicyDetailProps) {
  if (!selectedPolicy) {
    return null;
  }
  const policyAlert = getPolicyAlert(selectedPolicy);
  const policyItems = getPolicyItems(selectedPolicy, issuers);
  const showCoverageBlock = Boolean(selectedPolicy?.coverages?.length);

  return (
    <section className="w-full" data-cy="policy-section">
      <SectionHeader>Policy</SectionHeader>
      {policyAlert ? (
        <AlertBox data-cy="policy-alert" type="warning" className="mb-12">
          {policyAlert}
        </AlertBox>
      ) : null}
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <StructuredCard key="policy" label="Policy Profile" items={policyItems} data-cy="policy-profile" />

        <StructuredCard
          key="coverage"
          label="Coverages"
          items={!showCoverageBlock ? [] : getCoverageItems(selectedPolicy)}
        />
      </div>
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies; issuers: StoreIssuers }) {
  return {
    issuers: state.issuers.list.data,
    selectedPolicy: state.policies.list.selectedPropertyPolicy,
  };
}

export default connect(mapStateToProps)(PropertyPolicyDetails);
