import * as React from 'react';

import { Store } from '../modules/rootReducer';
import { connect } from 'react-redux';

type Props = {
  partnerKey: string;
};

function FiveHundred({ partnerKey }: Props) {
  React.useEffect(() => {
    analytics.page('500', { partner: partnerKey });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex justify-center items-center flex-col m-auto text-center">
      <i className="mb-4 text-[64px] text-red-500">
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="exclamation-circle"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
          <path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
        </svg>
      </i>

      <div className="mb-5 font-bold text-[21px] text-[#828282]">An Unexpected Error Occurred</div>
    </div>
  );
}

function mapStateToProps(state: Store) {
  return {
    partnerKey: state.agent.data.partner.key,
  };
}

export default connect(mapStateToProps)(FiveHundred);
