import '../index.css';

import * as React from 'react';

import { Route, Switch, withRouter } from 'react-router-dom';

import AgentTool from './AgentTool';
import Button from './Button';
import FourOhFour from './404';
import { RouteComponentProps } from 'react-router-dom';
import { Store } from '../modules/rootReducer';
import TrellisLogo from '../../public/img/trellis-logo.svg';
import { bootstrap } from '../modules/app/redux/bootstrap';
import { clearApplicants } from '../modules/applicants/redux';
import { clearPolicies } from '../modules/policies';
import { connect } from 'react-redux';
import { dispatch } from '../store';
import localDb from '../lib/localDb';
import { updateAgentData } from '../modules/agent/redux';

const PARTNER_PATHS = [
  'demo',
  'insuritas',
  'insuramatch',
  'dp',
  'itc',
  'matic',
  'vivint',
  'simplyioa',
  'simplyioa_dev',
  'thezebra',
];

interface AppProps extends RouteComponentProps {
  areTherePolicies: boolean;
  deviceId: string;
  isBootstrapDone: boolean;
  isRestartButtonVisible: boolean;
  phoneNumber: string;
  policyHolderFirstName?: string;
}

function App({
  isBootstrapDone,
  deviceId,
  isRestartButtonVisible,
  areTherePolicies,
  policyHolderFirstName,
  phoneNumber,
  history,
}: AppProps) {
  React.useEffect(() => {
    dispatch(bootstrap());
  }, []);

  const params = new URLSearchParams(document.location.search);
  const requestId = params.get('u') || '';

  // todo: temporary until localStorage fetching issue in sagas is resolved
  React.useEffect(() => {
    if (isBootstrapDone) {
      if (requestId) {
        analytics.identify(requestId, { deviceId });
      } else {
        localDb.getItem('agent').then(data => {
          if (data) {
            // @ts-ignore
            const email = data.email;
            dispatch(updateAgentData({ email }));
            analytics.identify(deviceId, { email });
          } else {
            dispatch(updateAgentData({ email: '' }));
          }
        });
      }
    }
  }, [isBootstrapDone, deviceId, requestId]);

  // Update page title on policy change
  React.useEffect(() => {
    if (areTherePolicies) {
      const title = policyHolderFirstName
        ? `${policyHolderFirstName} - ${phoneNumber ? `${phoneNumber} - ` : ''}Trellis Agent`
        : 'Trellis Agent';

      document.title = title;
    }
  }, [areTherePolicies, phoneNumber, policyHolderFirstName]);

  function resetAppState() {
    dispatch(clearPolicies());
    dispatch(clearApplicants());

    const basePath = history.location.pathname.split('/')[1];
    history.push(`/${basePath}`);
  }

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-50">
      <header className="flex items-center justify-between p-4 w-full">
        <img className="h-9" alt="Trellis logo" src={TrellisLogo} />
        {isRestartButtonVisible && (
          <Button className="flex items-center" onClick={resetAppState}>
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="rollback"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
              className="mr-2"
            >
              <path d="M793 242H366v-74c0-6.7-7.7-10.4-12.9-6.3l-142 112a8 8 0 0 0 0 12.6l142 112c5.2 4.1 12.9.4 12.9-6.3v-74h415v470H175c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-28.7 64-64V306c0-35.3-28.7-64-64-64z"></path>
            </svg>
            Restart
          </Button>
        )}
      </header>

      <Switch>
        <Route path={`/(${PARTNER_PATHS.join('|')})/:id?`} component={AgentTool} />
        <Route component={FourOhFour} />
      </Switch>
    </div>
  );
}

function mapStateToProps(state: Store) {
  const policies = state.policies.list.data;
  const areTherePolicies = !!policies?.length;
  const isWaitingOnApplicant = state.applicants.request.isLoading;
  const isWaitingForPolicy = state.policies.list.get.isLoading;
  const hasPolicyFetchError = !!state.policies.list.get.error;
  const policyHolderFirstName = policies[0]?.policyHolder?.name?.firstName;

  return {
    areTherePolicies,
    deviceId: state.agent.data.deviceId,
    isBootstrapDone: state.app.bootstrap.isDone,
    isRestartButtonVisible: areTherePolicies || isWaitingOnApplicant || isWaitingForPolicy || hasPolicyFetchError,
    phoneNumber: state.applicants.data.phoneNumber,
    policyHolderFirstName,
  };
}

export default withRouter(connect(mapStateToProps)(App));
