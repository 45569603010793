import * as React from 'react';

import { capitalize, getItem, isTruthy } from '../../utils/helpers';

import { CardItem } from '../../utils/types';
import { PersonalAutoIncident } from '@trellisconnect/types';
import SectionHeader from '../SectionHeader';
import { StorePolicies } from '../../modules/policies/redux';
import { TwoColumnStructuredCard } from '../StructuredCard';
import { centsToCurrency } from '../../lib/util';
import { claimTypes } from '../../utils/mappings';
import { connect } from 'react-redux';
import { reformatDateString } from '../../utils';

interface IncidentDetailProps extends React.ComponentProps<'section'> {
  incidents?: PersonalAutoIncident[];
}

function getIncidentItems(incident: PersonalAutoIncident): CardItem[] {
  const incidentDate = reformatDateString(incident?.date);

  const claimType = claimTypes[incident?.type || ''] ?? incident?.type;

  return [
    getItem('Driver Name', incident?.driverNameRaw ? capitalize(incident?.driverNameRaw) : ''),
    getItem('Incident Type', claimType || 'Unknown'),
    getItem('Type Details', incident?.typeDetails ?? 'Unknown'),
    getItem('Description', incident?.descriptionRaw ?? ''),
    getItem('Date', incidentDate),
    getItem('State', incident?.state ? capitalize(incident?.state) : ''),
    getItem('At Fault', incident?.atFault ? capitalize(incident?.atFault) : ''),
    getItem('Paid Amount', incident?.paidAmountCents ? centsToCurrency(incident?.paidAmountCents) : null),
    getItem(
      'Liability Medical Paid Amount',
      incident?.liabilityMedicalPaidAmountCents ? centsToCurrency(incident?.liabilityMedicalPaidAmountCents) : null,
    ),
  ].filter(isTruthy);
}

function getClaimLabel(incident: PersonalAutoIncident): string {
  const incidentType = incident?.type ?? 'Unknown Claim Type';
  const typeDetails = incident?.typeDetails;
  const incidentTypeClean = incidentType && claimTypes[incidentType] ? claimTypes[incidentType] : incidentType;

  if (incidentTypeClean && typeDetails) {
    return `${incidentTypeClean}: ${typeDetails}`;
  } else if (typeDetails) {
    return typeDetails;
  }

  return incidentTypeClean || 'Unknown Claim Type';
}

function IncidentDetails({ incidents }: IncidentDetailProps) {
  if (!incidents?.length) {
    return null;
  }

  return (
    <section className="w-full" data-cy="claims-section">
      <SectionHeader>Claims</SectionHeader>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {incidents.map((incident, i) => (
          <TwoColumnStructuredCard key={i} label={getClaimLabel(incident)} items={getIncidentItems(incident)} />
        ))}
      </div>
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies }) {
  const selectedPolicy = state.policies.list.selectedAutoPolicy;
  return {
    incidents: selectedPolicy?.incidents,
  };
}

export default connect(mapStateToProps)(IncidentDetails);
