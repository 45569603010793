import * as React from 'react';

import { Address, PropertyMortgage } from '@trellisconnect/types';
import { getItem, isTruthy } from '../../utils/helpers';

import SectionHeader from '../SectionHeader';
import { StorePolicies } from '../../modules/policies/redux';
import StructuredCard from '../StructuredCard';
import { connect } from 'react-redux';
import { formatAddress } from '../../utils';

interface MortgageDetailProps extends React.ComponentProps<'section'> {
  mortgages?: PropertyMortgage[] | null;
}

function MortgageDetails({ mortgages }: MortgageDetailProps) {
  if (!mortgages?.length) {
    return null;
  }

  return (
    <section className="w-full" data-cy="mortgages-section">
      <SectionHeader>Mortgages</SectionHeader>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {mortgages.map((mortgage, i) => {
          const { type, lienHolder, lienHolderAddress, loanNumber } = mortgage;
          const formattedAdress = formatAddress(lienHolderAddress as Address);

          const mortgageDetails = [
            getItem('Lienholder', lienHolder || ''),
            getItem('Lienholder Address', formattedAdress || null),
            getItem('Type', type || ''),
            getItem('Loan #', loanNumber || ''),
          ].filter(isTruthy);

          return <StructuredCard key={i} items={mortgageDetails} />;
        })}
      </div>
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies }) {
  return {
    mortgages: state.policies.list.selectedPropertyPolicy?.mortgages,
  };
}

export default connect(mapStateToProps)(MortgageDetails);
