import 'react-phone-input-2/lib/style.css';

import * as React from 'react';

import AlertBox from './AlertBox';
import Button from './Button';
import Card from './Card';
import EmailModal from './EmailModal';
import { PARTNER_NAMES } from '../lib/partners';
import ReactPhoneInput from 'react-phone-input-2';
import { Store } from '../modules/rootReducer';
import { connect } from 'react-redux';
import { dispatch } from '../store';
import { requestAccount } from '../modules/applicants/redux';

const FAILED_TO_SEND_SMS_ERROR = 'Failed to send SMS';
const SMS_BLACKLIST_PARTNERS = [PARTNER_NAMES.vivint];

const containerStyle = {};

interface PhoneSearchProps {
  partnerName: string;
  isLoading: boolean;
  error: any;
}

function PhoneSearch({ partnerName, isLoading, error }: PhoneSearchProps) {
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [isPending, setIsPending] = React.useState(false);

  const isDisabled = !phoneNumber.length || isPending;

  async function onSubmit() {
    setIsPending(true);

    const formattedPhoneNumber = phoneNumber.length > 0 ? `+1${phoneNumber.replace(/\s+/g, ' ')}` : '';

    dispatch(requestAccount(formattedPhoneNumber));
  }

  function onPhoneChange(value: any) {
    const rawValue = value.replace(/[^0-9]+/g, '');
    setPhoneNumber(rawValue);
  }

  const phoneInputStyle = {
    height: '48px',
    background: '#ffffff',
    border: '1px solid #e0e0e0',
    borderRadius: '29.5px',
    fontSize: '18px',
    outline: 'none',
    textAlign: 'center',
    width: '100%',
  } as React.CSSProperties;

  const failedToSendSMS = error && error.message === FAILED_TO_SEND_SMS_ERROR;
  const hideSendSMS = SMS_BLACKLIST_PARTNERS.includes(partnerName);

  return (
    <div className="max-w-lg px-4 mb-20">
      <header className="text-center mb-6">
        <h1 className="text-2xl text-gray-800">
          Welcome to
          <br />
          <span className="font-bold">Trellis Agent</span>
        </h1>
      </header>
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="text-center">
          <div className="h-16 flex justify-center items-center">
            <svg width="80" height="26" viewBox="0 0 106 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="1.5" y="1.5" width="103" height="31" rx="15.5" stroke="#2D2E82" strokeWidth="3" />
              <path
                d="M24.8847 10.29L21.2712 23.2385M17.9588 10.29L14.3452 23.2385"
                stroke="#2D2E82"
                strokeWidth="2.40903"
                strokeLinecap="round"
              />
              <path
                d="M12 20.1804L25.4426 20.0413M13.7926 13.4904L27.2352 13.3513"
                stroke="#2D2E82"
                strokeWidth="2.40903"
                strokeLinecap="round"
              />
              <path
                d="M43.8847 10.29L40.2712 23.2385M36.9588 10.29L33.3452 23.2385"
                stroke="#2D2E82"
                strokeWidth="2.40903"
                strokeLinecap="round"
              />
              <path
                d="M31 20.1804L44.4426 20.0413M32.7926 13.4904L46.2352 13.3513"
                stroke="#2D2E82"
                strokeWidth="2.40903"
                strokeLinecap="round"
              />
              <path
                d="M62.8847 10.29L59.2712 23.2385M55.9588 10.29L52.3452 23.2385"
                stroke="#2D2E82"
                strokeWidth="2.40903"
                strokeLinecap="round"
              />
              <path
                d="M50 20.1804L63.4426 20.0413M51.7926 13.4904L65.2352 13.3513"
                stroke="#2D2E82"
                strokeWidth="2.40903"
                strokeLinecap="round"
              />
              <rect x="68" y="7" width="1" height="19" fill="#2D2E82" />
            </svg>
          </div>

          <h3 className="text-lg text-gray-400 uppercase">- Step 1 - </h3>
          <p className="text-sm sm:text-base italic text-[#333] text-center">
            {hideSendSMS ? 'Generate link and send to customer' : `Enter customer's mobile phone #`}
          </p>
        </div>

        <div className="text-center">
          <div className="h-16 flex justify-center items-center">
            <svg width="43" height="51" viewBox="0 0 57 68" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M41 45V63C41 64.6569 39.6569 66 38 66H5C3.34315 66 2 64.6569 2 63V5C2 3.34315 3.34315 2 5 2H38C39.6569 2 41 3.34315 41 5V8"
                stroke="#2D2E82"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <rect x="16" y="58" width="11" height="3" rx="1.5" fill="#2D2E82" />
              <rect x="26" y="22" width="23" height="3" rx="1.5" fill="#2D2E82" />
              <path
                d="M50 14H25C22.2386 14 20 16.2386 20 19V34C20 36.7614 22.2386 39 25 39H27.95C28.1157 39 28.25 39.1343 28.25 39.3V45.352C28.25 45.6076 28.5492 45.7461 28.7441 45.5808L35.1005 40.1875C36.004 39.4208 37.1505 39 38.3354 39H50C52.7614 39 55 36.7614 55 34V19C55 16.2386 52.7614 14 50 14Z"
                stroke="#2D2E82"
                strokeWidth="3"
              />
              <rect x="26" y="29" width="23" height="3" rx="1.5" fill="#2D2E82" />
            </svg>
          </div>
          <h3 className="text-lg text-gray-400 uppercase">- Step 2 - </h3>
          <p className="text-sm sm:text-base italic text-[#333] text-center">Customer securely links account</p>
        </div>

        <div className="text-center">
          <div className="h-16 flex justify-center items-center">
            <svg width="43" height="55" viewBox="0 0 43 55" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="9" y="8" width="9" height="3" rx="1.5" fill="#2D2E82" />
              <rect x="9" y="13" width="25" height="3" rx="1.5" fill="#2D2E82" />
              <rect x="9" y="18" width="25" height="3" rx="1.5" fill="#2D2E82" />
              <rect x="9" y="23" width="13" height="3" rx="1.5" fill="#2D2E82" />
              <rect x="23" y="23" width="11" height="13" rx="2" fill="#2D2E82" />
              <rect x="9" y="28" width="13" height="3" rx="1.5" fill="#2D2E82" />
              <rect x="9" y="33" width="13" height="3" rx="1.5" fill="#2D2E82" />
              <rect x="21" y="43" width="13" height="3" rx="1.5" fill="#2D2E82" />
              <rect x="9" y="38" width="25" height="3" rx="1.5" fill="#2D2E82" />
              <rect x="1.5" y="1.5" width="40" height="52" rx="3.5" stroke="#2D2E82" strokeWidth="3" />
            </svg>
          </div>
          <h3 className="text-lg text-gray-400 uppercase">- Step 3 - </h3>
          <p className="text-sm sm:text-base italic text-[#333] text-center">Customer and you review policy together</p>
        </div>
      </div>

      <Card className="mb-4">
        <h4 className="text-center mb-4 text-gray-700">Supported Carriers</h4>

        <div className="text-xs grid grid-cols-2 sm:grid-cols-4 gap-2">
          <ul className="pl-4">
            <li>21st Century</li>
            <li>AAA NCNU</li>
            <li>AAA SoCal</li>
            <li>Allstate</li>
            <li>American Family</li>
            <li>Auto Owners</li>
            <li>Bristol West Insurance</li>
          </ul>
          <ul className="pl-4">
            <li>Dairyland</li>
            <li>Direct Auto</li>
            <li>Erie Insurance</li>
            <li>Esurance</li>
            <li>Farmers</li>
            <li>GEICO</li>
            <li>Liberty Mutual</li>
            <li>MAPFRE</li>
          </ul>
          <ul className="pl-4">
            <li>Mercury</li>
            <li>Metlife</li>
            <li>Metromile</li>
            <li>Nationwide</li>
            <li>Progressive</li>
            <li>Root</li>
            <li>Safe Auto</li>
            <li>Safeco</li>
          </ul>
          <ul className="pl-4">
            <li>State Farm</li>
            <li>The General</li>
            <li>The Hartford</li>
            <li>Travelers</li>
            <li>USAA</li>
            <li>Wawanesa</li>
          </ul>
        </div>
      </Card>

      {failedToSendSMS && (
        <AlertBox type="error" className="mb-4 w-full">
          <div className="text-center">
            <h3 className="text-lg font-bold">Failed to send SMS</h3>
            <p>Please check the number and try again.</p>
          </div>
        </AlertBox>
      )}

      {!hideSendSMS && (
        <div>
          <div className="mb-4">
            <ReactPhoneInput
              country={'us'}
              disableDropdown
              value={phoneNumber}
              onChange={onPhoneChange}
              containerStyle={containerStyle}
              inputStyle={phoneInputStyle}
              onlyCountries={['us']}
              disableCountryCode
              placeholder="(555) 555-5555"
            />
          </div>
          <Button className="w-full" onClick={onSubmit} disabled={isDisabled}>
            Request Permission via SMS
          </Button>
        </div>
      )}

      {hideSendSMS && (
        <div>
          <Button className="w-full" onClick={onSubmit}>
            Generate Link and Send it Myself
          </Button>
        </div>
      )}

      <EmailModal />
    </div>
  );
}

function mapStateToProps(state: Store) {
  return {
    partnerName: state.agent.data.partner.name,
    isLoading: state.applicants.request.isLoading,
    error: state.applicants.request.error,
  };
}

export default connect(mapStateToProps)(PhoneSearch);
