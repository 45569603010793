import * as React from 'react';

import { CardItem, PropertyPolicy } from '../../utils/types';
import { getBirthdayHint, getBirthdayValue } from '../auto/DriverDetails';
import { getItem, isTruthy } from '../../utils/helpers';

import { HouseholdMember } from '@trellisconnect/types';
import SectionHeader from '../SectionHeader';
import { StorePolicies } from '../../modules/policies/redux';
import StructuredCard from '../StructuredCard';
import { connect } from 'react-redux';

interface HouseholdMemberDetailProps extends React.ComponentProps<'section'> {
  selectedPolicy: PropertyPolicy | null;
}

function getHouseholdMemberName(member: HouseholdMember): string {
  if (!member || !member.name || (!member.name.firstName && !member.name.lastName)) {
    return '';
  }

  if (member.name.firstName && !member.name.lastName) {
    return member.name.firstName;
  }

  return `${member.name.firstName} ${member.name.lastName}`;
}

function getHouseholdDetails(member: HouseholdMember): CardItem[] {
  const memberInfo: CardItem[] = [];
  const gender = member?.gender;
  const occupation = member?.occupation;
  const relationship = member?.relationship;
  const birthdayRange = member?.birthdayRange;
  const birthdayFieldValue = birthdayRange ? getBirthdayValue({ birthdayRange }) : null;
  const birthdayFieldHint = birthdayRange ? getBirthdayHint({ birthdayRange }) : null;

  if (birthdayFieldValue) {
    const birthdayCardItem = getItem('Date of Birth (DOB)', birthdayFieldValue, birthdayFieldHint || '');
    if (birthdayCardItem) {
      memberInfo.push(birthdayCardItem);
    }
  }

  if (gender) {
    memberInfo.push({
      label: 'Gender',
      value: gender,
    });
  }

  if (occupation) {
    memberInfo.push({
      label: 'Occupation',
      value: occupation,
    });
  }

  if (relationship) {
    memberInfo.push({
      label: 'Relationship',
      value: relationship,
    });
  }

  return [undefined, ...memberInfo].filter(isTruthy);
}

function HouseholdMemberDetails({ selectedPolicy }: HouseholdMemberDetailProps) {
  const householdMembers = selectedPolicy?.householdMembers;

  if (!selectedPolicy || !householdMembers?.length) {
    return null;
  }

  return (
    <section className="w-full" data-cy="household-section">
      <SectionHeader>Household Members</SectionHeader>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {householdMembers &&
          householdMembers.map((m, i) => (
            <StructuredCard key={(i + 1) * 2} label={getHouseholdMemberName(m)} items={getHouseholdDetails(m)} />
          ))}
      </div>
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies }) {
  return {
    selectedPolicy: state.policies.list.selectedPropertyPolicy,
  };
}

export default connect(mapStateToProps)(HouseholdMemberDetails);
