import { Payment } from '@trellisconnect/types';
import { centsToCurrency } from '../lib/util';

export function formatRecentPayment(payment: Payment): string {
  const paymentAmount = payment.amount !== null && payment.amount >= 0 ? centsToCurrency(payment.amount, false) : null;

  if (paymentAmount && payment.date) {
    const paymentDate = new Date(payment.date);
    const formattedDate = paymentDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC',
    });

    return `${paymentAmount}${paymentDate ? ` (${formattedDate})` : ''}`;
  }

  return paymentAmount ? paymentAmount : '';
}
