import { FinancialResponsibilityCase } from '@trellisconnect/types';

export function getFinancialResponsibilityCasesValue(
  financialResponsibilityCases?: FinancialResponsibilityCase[] | null,
): string | null {
  if (financialResponsibilityCases && financialResponsibilityCases.length) {
    return financialResponsibilityCases.reduce((result, frCase) => {
      if (result !== '') {
        result = `${result}\n\n`;
      }

      return `${result}${frCase.type} ${frCase.state}${frCase.caseNumber ? `\nCase: ${frCase.caseNumber}` : ''}`;
    }, '');
  }

  return null;
}
