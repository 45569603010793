import * as React from 'react';

import { PolicyDocument } from '../utils/types';
import PolicyDocuments from './PolicyDocuments';
import SectionHeader from './SectionHeader';
import { StorePolicies } from '../modules/policies/redux';
import { connect } from 'react-redux';

interface DocumentProps extends React.ComponentProps<'section'> {
  documents?: PolicyDocument[];
}

function DocumentsSection({ documents }: DocumentProps) {
  if (!documents?.length) {
    return null;
  }

  return (
    <section className="w-full" data-cy="documents-section">
      <SectionHeader>Documents</SectionHeader>
      <PolicyDocuments documents={documents} />
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies }) {
  const selectedPolicy = state.policies.list.selectedAutoPolicy || state.policies.list.selectedPropertyPolicy;
  return {
    documents: selectedPolicy?.documents,
  };
}

export default connect(mapStateToProps)(DocumentsSection);
