import * as React from 'react';

import Card from '../Card';
import FieldGroup from '../FieldGroup';
import { PropertyDetails as Property } from '@trellisconnect/types';
import SectionHeader from '../SectionHeader';
import { StorePolicies } from '../../modules/policies/redux';
import { connect } from 'react-redux';
import { formatAddress } from '../../utils';
import formatDate from 'date-fns/format';
import { parseISO } from 'date-fns';

interface PropertyDetailProps {
  property?: Property;
}

function PropertyDetails({ property }: PropertyDetailProps) {
  if (!property) {
    return null;
  }

  const features = property.features;
  const hasBurglarAlarm = features?.hasBurglarAlarm;
  const hasFireAlarm = features?.hasFireAlarm;
  const hasSupplementalHeating = features?.hasSupplementalHeating;

  const purchaseDate = property.purchaseDate ? formatDate(parseISO(property.purchaseDate), 'M/d/yyyy') : null;
  const squareFootage = property.squareFeet ? `${property.squareFeet} sqft` : null;
  const numberOfBathrooms = property.numberOfBathrooms;
  const numberOfStories = property.numberOfStories;
  const numberOfKitchens = property.numberOfKitchens;
  const numberOfFireplaces = property.numberOfFireplaces;
  const numberOfHouseholdMembers = property.numberOfHouseholdMembers;
  const yearBuilt = property.yearBuilt;
  const constructionType = property.constructionType;
  const foundationType = property.foundationType;
  const dwellingType = property.dwellingType;
  const distanceToFireDepartmentInMiles = property.distanceToFireDepartmentInMiles
    ? `${property.distanceToFireDepartmentInMiles} miles`
    : null;
  const distanceToFireHydrantInFeet = property.distanceToFireHydrantInFeet
    ? `${property.distanceToFireHydrantInFeet} ft`
    : null;
  const formattedAdress = formatAddress(property?.address) || property?.addressRaw;
  const roofAge = property.ageOfRoofInYears;

  return (
    <section className="w-full" data-cy="property-section">
      <SectionHeader>Property</SectionHeader>
      <Card>
        <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
          <FieldGroup label="Address" value={formattedAdress} />
          <FieldGroup label="Roof Age" value={roofAge ? `${roofAge} years` : null} />
          <FieldGroup label="Purchase Date" value={purchaseDate} />
          <FieldGroup label="Square Footage" value={squareFootage} />
          <FieldGroup label="No. of Bathrooms" value={numberOfBathrooms?.toString()} />
          <FieldGroup label="No. of Stories" value={numberOfStories?.toString()} />
          <FieldGroup label="No. of Kitchens" value={numberOfKitchens?.toString()} />
          <FieldGroup label="No. of Fireplaces" value={numberOfFireplaces?.toString()} />
          <FieldGroup label="No. of Household Members" value={numberOfHouseholdMembers?.toString()} />

          {hasBurglarAlarm || hasFireAlarm || hasSupplementalHeating ? (
            <FieldGroup
              label="Features"
              value={
                <ul className="pl-4">
                  {hasBurglarAlarm ? <li>Burglar Alarm: {hasBurglarAlarm}</li> : null}
                  {hasFireAlarm ? <li>Fire Alarm: {hasFireAlarm}</li> : null}
                  {hasSupplementalHeating ? <li>Supplemental Heating: {hasSupplementalHeating}</li> : null}
                </ul>
              }
            />
          ) : null}

          <FieldGroup label="Year Built" value={yearBuilt?.toString()} />
          <FieldGroup label="Construction Type" value={constructionType} />
          <FieldGroup label="Foundation Type" value={foundationType} />
          <FieldGroup label="Dwelling Type" value={dwellingType} />
          <FieldGroup label="Distance to Fire Department" value={distanceToFireDepartmentInMiles} />
          <FieldGroup label="Distance to Fire Hydrant" value={distanceToFireHydrantInFeet} />
        </div>
      </Card>
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies }) {
  return {
    property: state.policies.list.selectedPropertyPolicy?.property,
  };
}

export default connect(mapStateToProps)(PropertyDetails);
