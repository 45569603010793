import * as React from 'react';

import AlertBox from './AlertBox';
import Spinner from './Spinner';
import { Store } from '../modules/rootReducer';
import { connect } from 'react-redux';

function parseSeconds(secondsRaw: number): string {
  let minutes: number | string = 0;

  if (secondsRaw > 59) {
    minutes = Math.floor(secondsRaw / 60);
    secondsRaw = secondsRaw % 60;
  }

  let seconds: number | string = secondsRaw;

  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

type Props = {
  phoneNumber: string;
};

const FetchingPolicy: React.FC<Props> = props => {
  const [timeWaited, setTimeWaited] = React.useState(0);

  React.useEffect(() => {
    let intervalRef: NodeJS.Timer = setInterval(() => {
      setTimeWaited(timeWaited => timeWaited + 1);
    }, 1000);

    return function cleanup() {
      clearInterval(intervalRef);
    };
  }, []);

  const connectedDescription = props.phoneNumber
    ? `Caller at number ${props.phoneNumber} connected their account`
    : 'Customer connected their account';

  return (
    <div className="flex justify-center items-center flex-col m-auto max-w-lg text-center">
      <AlertBox type="success" className="mb-6">
        <div className="flex items-center">
          <svg
            className="text-green-500 mr-4"
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="check-circle"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path>
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
          </svg>
          {connectedDescription}
        </div>
      </AlertBox>

      <Spinner />

      <h2 className="mb-5 font-bold text-xl text-gray-label">Getting policy info...</h2>
      <p className="mb-5 text-lg text-gray-label">
        This process usually takes 30-90 seconds (but can take up to 3 minutes)
      </p>
      <p className="mb-7 text-lg text-gray-label">Thank you for your patience!</p>

      <div className="mb-7 border-b border-b-[#e8e8e8] w-40" />

      <div className="mb-5 text-gray-500 opacity-70">
        You have waited for
        <span className="mb-5 text-lg font-bold"> {parseSeconds(timeWaited)}</span>
      </div>
    </div>
  );
};

function mapStateToProps(state: Store) {
  return {
    phoneNumber: state.applicants.data.phoneNumber,
  };
}

export default connect(mapStateToProps)(FetchingPolicy);
