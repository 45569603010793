import * as React from 'react';

import { DriverLicenseStatusTypes, Operator } from '@trellisconnect/types';
import { addDays, differenceInCalendarDays, parseISO } from 'date-fns';
import { capitalize, getItem, isTruthy } from '../../utils/helpers';

import { CardItem } from '../../utils/types';
import CopyText from '../copyText';
import { PersonalAutoPolicy } from '../../utils/types';
import SectionHeader from '../SectionHeader';
import { StorePolicies } from '../../modules/policies/redux';
import { TwoColumnStructuredCard } from '../StructuredCard';
import { connect } from 'react-redux';
import formatDate from 'date-fns/format';
import { getFinancialResponsibilityCasesValue } from '../../utils';

interface DriverDetailProps extends React.ComponentProps<'section'> {
  selectedPolicy: PersonalAutoPolicy | null;
}

const licenseStatuses: { [key in DriverLicenseStatusTypes]: string } = {
  [DriverLicenseStatusTypes.VALID_US_LICENSE]: 'Valid US License',
  [DriverLicenseStatusTypes.PERMIT]: 'Permit',
  [DriverLicenseStatusTypes.SUSPENDED]: 'Suspended',
  [DriverLicenseStatusTypes.RESTRICTED]: 'Restricted',
  [DriverLicenseStatusTypes.PERMANENTLY_REVOKED]: 'Permanently Revoked',
  [DriverLicenseStatusTypes.EXPIRED]: 'Expired',
  [DriverLicenseStatusTypes.SURRENDERED]: 'Surrendered',
  [DriverLicenseStatusTypes.NON_LICENSED]: 'Not Licensed',
};

export function getBirthdayValue({ birthdayRange }: Pick<Operator, 'birthdayRange'>): string {
  const birthdayRangeStart = birthdayRange?.start;
  const birthdayRangeEnd = birthdayRange?.end;
  const showExactBirthday = birthdayRangeStart && birthdayRangeStart === birthdayRangeEnd;

  if (showExactBirthday) {
    if (birthdayRange) {
      return formatDate(parseISO(birthdayRangeStart), 'M/d/yyyy');
    }
  } else if (birthdayRangeStart && birthdayRangeEnd) {
    const birthStartDate = parseISO(birthdayRangeStart);
    const birthEndDate = parseISO(birthdayRangeEnd);
    const birthDaysDiff = Math.abs(differenceInCalendarDays(birthStartDate, birthEndDate));
    const birthDaysDiffDeviation = Math.floor(birthDaysDiff / 2) || 0;
    const birthMidDate = addDays(birthStartDate, birthDaysDiffDeviation);

    return formatDate(birthMidDate, 'M/d/yyyy');
  }

  return '';
}

export function getBirthdayHint({ birthdayRange }: Pick<Operator, 'birthdayRange'>): JSX.Element | string | undefined {
  const birthdayRangeStart = birthdayRange?.start;
  const birthdayRangeEnd = birthdayRange?.end;
  if (birthdayRangeStart && birthdayRangeEnd && birthdayRangeStart !== birthdayRangeEnd) {
    const birthStartDate = parseISO(birthdayRangeStart);
    const birthEndDate = parseISO(birthdayRangeEnd);
    const birthDaysDiff = Math.abs(differenceInCalendarDays(birthStartDate, birthEndDate));
    const birthDaysDiffDeviation = Math.floor(birthDaysDiff / 2) || 0;

    return (
      <>
        {' '}
        <span>
          Approx.
          <br /> (&plusmn;
        </span>{' '}
        {Math.round(birthDaysDiffDeviation / 30.41666)} <span> months)</span>{' '}
      </>
    );
  }

  return undefined;
}

function getOperatorItems(operator: Operator): CardItem[] {
  const {
    driversLicenseState,
    driversLicenseNumber,
    driversLicenseStatus,
    birthdayRange,
    gender,
    ageLicensed,
    education,
    occupation,
    maritalStatus,
    relationship,
  } = operator;
  const financialResponsibilityCases = getFinancialResponsibilityCasesValue(operator?.financialResponsibilityCases);

  const driversLicenseText =
    driversLicenseState || driversLicenseNumber ? (
      <>
        {driversLicenseState && !driversLicenseNumber
          ? `Licensed in ${driversLicenseState}`
          : `${driversLicenseState} `}
        {driversLicenseNumber ? (
          <>
            {driversLicenseNumber}
            <div>
              <CopyText copyValue={driversLicenseNumber} notificationLabel="Driver's License #" />
            </div>
          </>
        ) : null}
      </>
    ) : null;

  const birthdayFieldValue = getBirthdayValue({ birthdayRange });
  const birthdayFieldHint = getBirthdayHint({ birthdayRange });

  return [
    getItem('Gender', capitalize(gender)),
    ...(birthdayFieldValue ? [getItem('Date of Birth (DOB)', birthdayFieldValue, birthdayFieldHint)] : []),
    getItem("Driver's License", driversLicenseText),
    getItem(
      "Driver's License Status",
      driversLicenseStatus ? licenseStatuses[driversLicenseStatus] || driversLicenseStatus : null,
    ),
    getItem('Age Licensed', ageLicensed?.toString()),
    getItem('Financial Responsibility Cases', financialResponsibilityCases),
    getItem('Education', capitalize(education)),
    getItem('Occupation', capitalize(occupation)),
    getItem('Marital Status', capitalize(maritalStatus)),
    getItem('Relationship to Primary', relationship ?? 'Unknown'),
  ].filter(isTruthy);
}

const renderOperators = (operators: Operator[]) => {
  return operators.map((operator, i) => {
    return (
      <TwoColumnStructuredCard
        key={operator?.name?.firstName || i}
        label={operator?.name?.firstName ? `${operator?.name?.firstName} ${operator?.name?.lastName || ''}` : 'Unknown'}
        items={getOperatorItems(operator)}
      />
    );
  });
};

function DriverDetails({ selectedPolicy }: DriverDetailProps) {
  const operators = selectedPolicy?.operators;

  if (!selectedPolicy || !operators?.length) {
    return null;
  }

  return (
    <section className="w-full" data-cy="drivers-section">
      <SectionHeader>Drivers</SectionHeader>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">{renderOperators(operators)}</div>
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies }) {
  return {
    selectedPolicy: state.policies.list.selectedAutoPolicy,
  };
}

export default connect(mapStateToProps)(DriverDetails);
