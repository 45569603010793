import * as React from 'react';

import { Store } from '../modules/rootReducer';
import { connect } from 'react-redux';

interface PageProps {
  partnerKey: string;
}

function PolicyFetchFailure({ partnerKey }: PageProps) {
  React.useEffect(() => {
    analytics.page('Policy Fetch Failure', { partner: partnerKey });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex justify-center items-center flex-col m-auto max-w-lg text-center">
      <svg
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="frown"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
        className="text-gray-300 text-7xl mb-6"
      >
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zM288 421a48.01 48.01 0 0 1 96 0 48.01 48.01 0 0 1-96 0zm376 272h-48.1c-4.2 0-7.8-3.2-8.1-7.4C604 636.1 562.5 597 512 597s-92.1 39.1-95.8 88.6c-.3 4.2-3.9 7.4-8.1 7.4H360a8 8 0 0 1-8-8.4c4.4-84.3 74.5-151.6 160-151.6s155.6 67.3 160 151.6a8 8 0 0 1-8 8.4zm24-224a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z"></path>
      </svg>

      <h2 className="mb-5 font-bold text-xl text-gray-label">Sorry, we are unable to retrieve the policy.</h2>
      <p className="text-lg text-gray-label mb-5">Unfortunately, we are unable to retrieve the policy at this time.</p>
      <p className="text-lg text-gray-label mb-5">We apologize for the inconvenience.</p>
    </div>
  );
}

function mapStateToProps(state: Store) {
  return {
    partnerKey: state.agent.data.partner.key,
  };
}

export default connect(mapStateToProps)(PolicyFetchFailure);
