import { agentReducer } from './agent';
import app from './app/redux';
import { applicantsReducer } from './applicants/redux';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { issuersReducer } from './issuers';
import { policiesReducer } from './policies';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  agent: agentReducer,
  app,
  applicants: applicantsReducer,
  issuers: issuersReducer,
  policies: policiesReducer,
  router: connectRouter(history),
});

export type Store = ReturnType<typeof rootReducer>;

export default rootReducer;
