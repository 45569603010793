import { PersonalAutoCoverage, PropertyCoverage } from '@trellisconnect/types';

import { CardItem } from './types';
import { centsToCurrency } from '../lib/util';
import { isNumber } from './helpers';

export function groupCoverages(coverageGroup: string, coverageValue: string): string {
  return coverageGroup !== '' ? `${coverageGroup} \n${coverageValue}` : coverageValue;
}

export function formatPersonalAutoCoverage(coverage: PersonalAutoCoverage): string {
  if (coverage?.isDeclined) return 'Declined';

  let coverageValue = '';

  if (coverage?.perPersonLimitCents && isNumber(coverage?.perPersonLimitCents)) {
    coverageValue = `${centsToCurrency(coverage?.perPersonLimitCents)} per person`;
  }

  if (coverage?.perAccidentLimitCents && isNumber(coverage?.perAccidentLimitCents)) {
    const perAccident = `${centsToCurrency(coverage?.perAccidentLimitCents)} per accident`;
    coverageValue = groupCoverages(coverageValue, perAccident);
  }

  if (coverage?.glassDeductibleCents !== null && isNumber(coverage?.glassDeductibleCents)) {
    const glassDeductible = `${centsToCurrency(coverage?.glassDeductibleCents)} glass deductible`;
    coverageValue = groupCoverages(coverageValue, glassDeductible);
  }

  if (coverage?.perDayLimitCents && isNumber(coverage?.perDayLimitCents)) {
    const perDay = `${centsToCurrency(coverage?.perDayLimitCents)} per day`;
    coverageValue = groupCoverages(coverageValue, perDay);
  }

  if (coverage?.perWeekLimitCents && isNumber(coverage?.perWeekLimitCents)) {
    const perWeek = `${centsToCurrency(coverage?.perWeekLimitCents)} per week`;
    coverageValue = groupCoverages(coverageValue, perWeek);
  }

  if (coverage?.perMonthLimitCents && isNumber(coverage?.perMonthLimitCents)) {
    const perMonth = `${centsToCurrency(coverage?.perMonthLimitCents)} per month`;
    coverageValue = groupCoverages(coverageValue, perMonth);
  }

  if (coverage?.deductibleCents && isNumber(coverage?.deductibleCents)) {
    const deductibleVal = `${centsToCurrency(coverage?.deductibleCents)} deductible`;
    coverageValue = groupCoverages(coverageValue, deductibleVal);
  }

  return coverageValue ? coverageValue : 'Included';
}

export function formatPropertyCoverage(coverage: PropertyCoverage): string {
  if (coverage?.isDeclined) return 'Declined';

  let coverageValue = '';

  if (coverage?.perOccurrenceLimitCents && isNumber(coverage?.perOccurrenceLimitCents)) {
    coverageValue = `${centsToCurrency(coverage.perOccurrenceLimitCents)} per occurrence`;
  }

  if (coverage?.earthquakeDeductibleCents && isNumber(coverage?.earthquakeDeductibleCents)) {
    const eqVal = `${centsToCurrency(coverage.earthquakeDeductibleCents)} earthquake deductible`;
    coverageValue = groupCoverages(coverageValue, eqVal);
  }

  if (coverage?.windstormDeductibleCents && isNumber(coverage?.windstormDeductibleCents)) {
    const wsVal = `${centsToCurrency(coverage.windstormDeductibleCents)} wind storm deductible`;
    coverageValue = groupCoverages(coverageValue, wsVal);
  }

  if (coverage?.hurricaneDeductibleCents && isNumber(coverage?.hurricaneDeductibleCents)) {
    const hcVal = `${centsToCurrency(coverage.hurricaneDeductibleCents)} hurricane deductible`;
    coverageValue = groupCoverages(coverageValue, hcVal);
  }

  if (coverage?.perPersonLimitCents && isNumber(coverage?.perPersonLimitCents)) {
    const ppLimitVal = `${centsToCurrency(coverage.perPersonLimitCents)} per person`;
    coverageValue = groupCoverages(coverageValue, ppLimitVal);
  }

  if (coverage?.maxMonthsLimit && isNumber(coverage?.maxMonthsLimit)) {
    const monthLimitVal =
      coverage?.maxMonthsLimit > 1 ? `${coverage.maxMonthsLimit} months max` : `${coverage.maxMonthsLimit} month max`;
    coverageValue = groupCoverages(coverageValue, monthLimitVal);
  }

  if (coverage?.deductibleCents && isNumber(coverage.deductibleCents)) {
    const deductibleVal = `${centsToCurrency(coverage.deductibleCents)} deductible`;
    coverageValue = groupCoverages(coverageValue, deductibleVal);
  }

  return coverageValue ? coverageValue : 'Included';
}

export function sortCoveragesByLabel(a: CardItem, b: CardItem) {
  return a.label && b.label && a.label < b.label ? -1 : 0;
}
