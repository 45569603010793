// UPDATE

import { Action } from '../util';
import { PartnerInfo } from '../../lib/partners';

const UPDATE = 'agent/UPDATE';

type UpdateAction = {
  type: typeof UPDATE;
  payload: UpdateActionPayload;
};
type UpdateActionPayload = {
  deviceId?: string;
  email?: string;
  partner?: PartnerInfo;
};

export function updateAgentData(data: UpdateActionPayload): UpdateAction {
  return {
    type: UPDATE,
    payload: data,
  };
}

// REDUCER

type AgentData = {
  deviceId: string;
  email: string;
  partner: PartnerInfo;
};
type StoreAgent = {
  data: AgentData;
  isDone: boolean;
};

const initialState: StoreAgent = {
  data: {
    deviceId: '',
    email: '',
    partner: {
      apiKey: '',
      key: '',
      name: '',
      applicationId: '',
    },
  },
  // todo: can we get rid of 'isDone' yet?
  isDone: false,
};

export function agentReducer(state: StoreAgent = initialState, action: Action): StoreAgent {
  switch (action.type) {
    // UPDATE
    case UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        isDone: true,
      };

    default:
      return state;
  }
}
