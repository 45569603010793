import * as React from 'react';

import classnames from 'classnames';

type Props = {
  label: string | undefined;
  value: string | JSX.Element | null | undefined;
};

const FieldGroup = ({ label, value, className, ...rest }: React.ComponentProps<'div'> & Props) => {
  if (!label || !value) {
    return null;
  }

  return (
    <div className={classnames('mb-4', className)} {...rest}>
      <h4 data-cy="item-label" className="text-xs break-words text-gray-label mb-0 leading-normal">
        {label}
      </h4>
      <div data-cy="item-value" className="text-gray-800 text-sm">
        {value}
      </div>
    </div>
  );
};

export default FieldGroup;
