import * as React from 'react';

export default function SectionHeader({ children }: React.ComponentProps<'div'>) {
  return (
    <div className="flex items-center py-4">
      <hr className="border-t border-gray-200 flex-1" />
      <p className="text-lg font-bold mx-4 my-0">{children}</p>
      <hr className="border-t border-gray-200 flex-1" />
    </div>
  );
}
