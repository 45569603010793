import * as React from 'react';

import classNames from 'classnames';

interface AlertBoxProps extends React.ComponentProps<'div'> {
  type: 'warning' | 'error' | 'info' | 'success';
}

export default function AlertBox({ children, className, type, ...rest }: AlertBoxProps) {
  return (
    <div
      className={classNames(
        'p-4 border text-gray-600',
        {
          'border-yellow-200 bg-yellow-100': type === 'warning',
          'border-red-200 bg-red-100': type === 'error',
          'border-blue-200 bg-blue-100': type === 'info',
          'border-[#b7eb8f] bg-[#f6ffed]': type === 'success',
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
