import * as React from 'react';

import {
  StorePolicies,
  getPolicyTypeReadable,
  setSelectedAutoPolicy,
  setSelectedPropertyPolicy,
  setSelectedGenericPolicy,
} from '../modules/policies/redux';
import { isPersonalAutoPolicy, isPropertyPolicy } from '../utils/helpers';
import { isPolicyCanceled, isPolicyExpired, isPolicyFuture } from '../lib/util';

import { Policy } from '../utils/types';
import { PolicyStatus } from '../utils/mappings';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { dispatch } from '../store';

interface PolicyMenuProps extends React.ComponentProps<'div'> {
  policies: Policy[];
  selectedPolicyId: string;
}

function getPolicyStatus(policy: Policy): string {
  if (isPolicyCanceled(policy)) {
    return `(${PolicyStatus.CANCELED}) `;
  } else if (isPolicyFuture(policy)) {
    return `(${PolicyStatus.FUTURE} Policy) `;
  } else if (isPolicyExpired(policy)) {
    return `(${PolicyStatus.EXPIRED}) `;
  } else if (policy.active === false) {
    return `(${PolicyStatus.INACTIVE}) `;
  }

  return '';
}

const renderTabContent = (policy: Policy): string => {
  const policyStatus = getPolicyStatus(policy);
  const policyTypeText = getPolicyTypeReadable(policy?.policyType);
  const policyNumberText = policy.policyNumber ? `#${policy.policyNumber}` : 'Unknown Policy #';

  return `${policyStatus}${policyTypeText}: ${policyNumberText}`;
};

function PolicyMenu({ policies, selectedPolicyId }: PolicyMenuProps) {
  if (!policies?.length) {
    return null;
  }

  const renderMenuItem = (pol: Policy) => {
    function onSelectedPolicy(policy: Policy) {
      if (isPersonalAutoPolicy(policy)) {
        dispatch(setSelectedAutoPolicy(policy));
      } else if (isPropertyPolicy(policy)) {
        dispatch(setSelectedPropertyPolicy(policy));
      } else {
        dispatch(setSelectedGenericPolicy(policy));
      }
    }

    return (
      <button
        className={classNames('mr-2 mb-2 text-sm cursor-pointer border border-trellis-blue rounded-sm p-2', {
          'text-white bg-trellis-blue': pol.id === selectedPolicyId,
          'text-trellis-blue bg-transparent': pol.id !== selectedPolicyId,
        })}
        onClick={() => onSelectedPolicy(pol)}
        key={pol.id}
        data-cy="policy-tab"
      >
        {renderTabContent(pol)}
      </button>
    );
  };

  return (
    <div className="my-5 w-full" data-cy="policy-menu">
      <div className="mb-3">
        <span className="font-bold">{policies.length} </span>
        policies found
      </div>

      <div className="flex flex-wrap">{policies.map(renderMenuItem)}</div>
    </div>
  );
}

function mapStateToProps(state: { policies: StorePolicies }) {
  const selectedPolicy = state.policies.list.selectedAutoPolicy || state.policies.list.selectedPropertyPolicy;
  const firstPolicyId = state.policies.list.data[0]?.id ?? '';
  return {
    policies: state.policies.list.data,
    selectedPolicyId: selectedPolicy?.id || firstPolicyId,
  };
}

export default connect(mapStateToProps)(PolicyMenu);
