import { Action, AsyncState } from '../../util';
import { CardItem, PersonalAutoPolicy, Policy, PropertyPolicy } from '../../../utils/types';
import { PersonalAutoCoverage, Vehicle } from '@trellisconnect/types';
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { isPersonalAutoPolicy, isPropertyPolicy } from '../../../utils/helpers';

import { SagaIterator } from 'redux-saga';
import { autoPolicyCoverageFields } from '../../../utils/mappings';
import env from '../../../lib/env';
import { formatPersonalAutoCoverage } from '../../../utils/coverages';
import { getIssuer } from '../../issuers/redux/list';
import { isPolicyExpired } from '../../../lib/util';
import request from '../../../lib/request';

// GET

const GET_INIT = 'policies/list/GET';
const GET_PENDING = 'policies/list/GET_PENDING';
const GET_SUCCESS = 'policies/list/GET_SUCCESS';
const GET_FAILURE = 'policies/list/GET_FAILURE';
const SET_SELECTED_GENERIC_POLICY = 'policies/SET_SELECTED_GENERIC_POLICY';
const SET_SELECTED_AUTO_POLICY = 'policies/SET_SELECTED_AUTO_POLICY';
const SET_SELECTED_PROPERTY_POLICY = 'policies/SET_SELECTED_PROPERTY_POLICY';

type GetInitAction = {
  type: typeof GET_INIT;
  apiKey: string;
  payload: string;
};
type GetPendingAction = {
  type: typeof GET_PENDING;
};
type GetSuccessAction = {
  type: typeof GET_SUCCESS;
  payload: Policy[];
};
type GetFailureAction = {
  type: typeof GET_FAILURE;
  payload: Error;
};
type SelectedGenericPolicyAction = {
  type: typeof SET_SELECTED_GENERIC_POLICY;
  payload: Policy;
};
type SelectedAutoPolicyAction = {
  type: typeof SET_SELECTED_AUTO_POLICY;
  payload: PersonalAutoPolicy;
};
type SelectedPropertyPolicyAction = {
  type: typeof SET_SELECTED_PROPERTY_POLICY;
  payload: PropertyPolicy;
};

export function getPolicies(accountId: string, apiKey: string): GetInitAction {
  return {
    type: GET_INIT,
    apiKey: apiKey,
    payload: accountId,
  };
}
function getPoliciesPending(): GetPendingAction {
  return { type: GET_PENDING };
}
function getPoliciesSuccess(policies: Policy[]): GetSuccessAction {
  return {
    type: GET_SUCCESS,
    payload: policies,
  };
}
function getPoliciesFailure(error: Error): GetFailureAction {
  return {
    type: GET_FAILURE,
    payload: error,
  };
}

export function getPolicyTypeReadable(policyType: string | null | undefined): string {
  return (
    policyType
      ?.toLowerCase()
      .replace('_', ' ')
      .replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) || 'Other'
  );
}

export function setSelectedAutoPolicy(policy: PersonalAutoPolicy): SelectedAutoPolicyAction {
  return { type: SET_SELECTED_AUTO_POLICY, payload: policy };
}

export function setSelectedPropertyPolicy(policy: PropertyPolicy): SelectedPropertyPolicyAction {
  return { type: SET_SELECTED_PROPERTY_POLICY, payload: policy };
}

export function setSelectedGenericPolicy(policy: Policy): SelectedGenericPolicyAction {
  return { type: SET_SELECTED_GENERIC_POLICY, payload: policy };
}

type PoliciesApiPayload = {
  clientId: string;
  accountId: string;
};

type PoliciesApiResponse = PoliciesApiResponseStatus & PoliciesApiResponseData;
type PoliciesApiResponseStatus = {
  error: string;
  success: false;
};
type PoliciesApiResponseData = {
  data: Policy[];
};

export function* getPoliciesTask(action: GetInitAction): SagaIterator {
  yield put(getPoliciesPending());

  try {
    const accountId = action.payload;
    if (!accountId) throw new Error('Unable to get policies. No accountId');

    yield put(getIssuer(accountId, action.apiKey));

    let missingPolicy = true;

    while (missingPolicy) {
      try {
        const policieisApiPayload: PoliciesApiPayload = {
          clientId: action.apiKey,
          accountId,
        };
        const apiResponse: PoliciesApiResponse = yield call(
          request.post,
          env.ACCOUNT_POLICIES_RETRIEVAL_URL,
          policieisApiPayload,
        );

        if (!apiResponse.success || apiResponse.error) {
          throw new Error(apiResponse.error);
        }

        yield put(getPoliciesSuccess(apiResponse.data));
        missingPolicy = false;
      } catch (err) {
        if ((err as Error).message !== 'POLICIES NOT READY YET') {
          throw err;
        }
      }

      yield delay(2000);
    }
  } catch (err) {
    yield put(getPoliciesFailure(err as Error));
  }
}

export function* getPoliciesWatcher(): SagaIterator {
  yield takeEvery(GET_INIT, getPoliciesTask);
}

// CLEAR

const CLEAR = 'policies/list/CLEAR';

type ClearAction = { type: typeof CLEAR };

export function clearPolicies(): ClearAction {
  return { type: CLEAR };
}

// REDUCER

type StorePolicies = {
  data: Policy[];
  get: AsyncState & {
    hasRun: boolean;
  };
  selectedPolicy: Policy | null;
  selectedAutoPolicy: PersonalAutoPolicy | null;
  selectedPropertyPolicy: PropertyPolicy | null;
  selectedPolicyCoverages: CardItem[];
};
const initialState: StorePolicies = {
  // the alternate line here is for having policies right away in dev
  data: [],
  // data: [require('./list.dev').default(1), require('./list.dev').default(2)],
  get: {
    error: null,
    hasRun: false,
    isLoading: false,
  },
  selectedPolicy: null,
  selectedAutoPolicy: null,
  selectedPropertyPolicy: null,
  selectedPolicyCoverages: [],
};

function sortPolicies(a: Policy, b: Policy) {
  const aIsActive = Boolean(a.active && !isPolicyExpired(a));
  const bIsActive = Boolean(b.active && !isPolicyExpired(b));
  return aIsActive && !bIsActive ? -1 : 0;
}

function getPersonalAutoCoverageItems(vehicles: Vehicle[]): CardItem[] {
  if (!vehicles?.length) {
    return [];
  }

  if (vehicles.length === 1) {
    // If only 1 vehicle, filter to return policy coverages
    const onlyVehile = vehicles[0];
    return onlyVehile?.coverages
      ? onlyVehile.coverages.reduce((policyCoverages: CardItem[], coverage: PersonalAutoCoverage) => {
          const mappedName = autoPolicyCoverageFields[coverage.name];

          if (mappedName) {
            return [
              ...policyCoverages,
              {
                label: mappedName,
                value: formatPersonalAutoCoverage(coverage),
              },
            ];
          }

          return policyCoverages;
        }, [])
      : [];
  }

  const policyLevelCoveragesMap: { [key: string]: CardItem & { vehicle: Vehicle } } = {};

  vehicles.map((vehicle: Vehicle) => {
    if (vehicle?.coverages) {
      vehicle.coverages.map(coverage => {
        const currentCoverage = policyLevelCoveragesMap[coverage.name];

        if (!currentCoverage) {
          policyLevelCoveragesMap[coverage.name] = {
            label: autoPolicyCoverageFields[coverage.name],
            value: formatPersonalAutoCoverage(coverage),
            vehicle,
          };
        } else {
          if (!coverage.isDeclined && currentCoverage.value === 'Declined') {
            const currentVehicle = currentCoverage.vehicle;
            policyLevelCoveragesMap[coverage.name] = {
              label: currentCoverage.label,
              value: formatPersonalAutoCoverage(coverage),
              hint: `Declined for ${currentVehicle.year} ${currentVehicle.make} ${currentVehicle.model}`,
              vehicle,
            };
          }

          if (coverage.isDeclined && currentCoverage.value !== 'Declined') {
            policyLevelCoveragesMap[coverage.name] = {
              ...currentCoverage,
              hint: `Declined for ${vehicle.year} ${vehicle.make} ${vehicle.model}`,
            };
          }

          if (coverage.isDeclined && currentCoverage.value === 'Declined') {
            policyLevelCoveragesMap[coverage.name] = {
              ...currentCoverage,
              hint: '',
            };
          }
        }

        return coverage;
      });
    }
    return vehicle;
  });

  return Object.values(policyLevelCoveragesMap);
}

export function listReducer(state: StorePolicies = initialState, action: Action): StorePolicies {
  switch (action.type) {
    // GET
    case GET_PENDING:
      return { ...state, get: { isLoading: true, hasRun: false, error: null } };

    case GET_SUCCESS:
      const sortedPolicies = action.payload.sort(sortPolicies);
      const selectedPolicy = sortedPolicies.length ? sortedPolicies[0] : null;

      return {
        ...state,
        get: { isLoading: false, hasRun: true, error: null },
        data: sortedPolicies,
        ...(isPersonalAutoPolicy(selectedPolicy) && { selectedAutoPolicy: selectedPolicy }),
        ...(isPropertyPolicy(selectedPolicy) && { selectedPropertyPolicy: selectedPolicy }),
        selectedPolicyCoverages: isPersonalAutoPolicy(selectedPolicy)
          ? getPersonalAutoCoverageItems(selectedPolicy?.vehicles)
          : [],
      };

    case GET_FAILURE:
      return {
        ...state,
        get: { isLoading: false, hasRun: true, error: action.payload },
      };

    case CLEAR:
      return initialState;

    case SET_SELECTED_AUTO_POLICY:
      const selectedAutoPolicy: PersonalAutoPolicy = action.payload;
      return {
        ...state,
        selectedAutoPolicy,
        selectedPolicy: null,
        selectedPropertyPolicy: null,
        selectedPolicyCoverages: getPersonalAutoCoverageItems(selectedAutoPolicy?.vehicles),
      };
    case SET_SELECTED_PROPERTY_POLICY:
      return { ...state, selectedPropertyPolicy: action.payload, selectedAutoPolicy: null, selectedPolicy: null };
    case SET_SELECTED_GENERIC_POLICY:
      return {
        ...state,
        selectedPropertyPolicy: action.payload,
        selectedAutoPolicy: null,
        selectedPolicy: action.payload,
      };

    default:
      return state;
  }
}
