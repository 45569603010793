import React from 'react';
import classnames from 'classnames';

export default function Button({ children, className, disabled, ...rest }: React.ComponentProps<'button'>) {
  return (
    <button
      className={classnames(
        'text-center rounded-full py-2 px-4 text-base uppercase transition-all duration-200',
        {
          'bg-trellis-blue hover:bg-gray-900 hover:text-white text-white cursor-pointer': !disabled,
          'bg-gray-300 text-gray-600 cursor-not-allowed pointer-events-none': disabled,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </button>
  );
}
