import * as React from 'react';

type CopyTextProps = {
  copyValue: string;
  notificationLabel: string;
};

function CopyText({ copyValue, notificationLabel }: CopyTextProps) {
  const [wasSuccessful, setWasSuccessful] = React.useState(false);
  const [successTimeout, setSuccessTimeout] = React.useState<NodeJS.Timeout>();

  React.useEffect(() => {
    return () => {
      if (successTimeout) {
        clearTimeout(successTimeout);
      }
    };
  }, [successTimeout]);

  function copy() {
    if (wasSuccessful) return;

    // Create hidden input for browser to interact with
    const input = document.createElement('input');
    input.type = 'text';
    input.style.opacity = '0';
    input.style.position = 'absolute';
    input.value = copyValue;

    document.body.appendChild(input);

    const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (isIOS) {
      // iOS Safari security measures relative to document.execCommand() requires specific logic
      const saveEditable = input.contentEditable;
      const saveReadOnly = input.readOnly;
      input.contentEditable = 'true';
      input.readOnly = false;

      const range = document.createRange();
      range.selectNodeContents(input);
      const sel = window.getSelection()!;
      sel.removeAllRanges();
      sel.addRange(range);
      input.setSelectionRange(0, 999999);
      input.contentEditable = saveEditable;
      input.readOnly = saveReadOnly;
    } else {
      window.getSelection()?.removeAllRanges();
      input.setSelectionRange(0, input.value.length);
    }

    try {
      const onClipBoard = (e: ClipboardEvent) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const cd = e.clipboardData || (window.clipboardData as DataTransfer);

        if (cd) {
          cd.setData('text', copyValue);
        }

        e.preventDefault();
        document.removeEventListener('copy', onClipBoard);
      };

      document.addEventListener('copy', onClipBoard);
      document.execCommand('copy');

      setWasSuccessful(true);

      if (successTimeout) {
        clearTimeout(successTimeout);
      }
      setSuccessTimeout(
        setTimeout(() => {
          setWasSuccessful(false);
        }, 3000),
      );
    } catch (err) {
      console.log(`Error copying ${notificationLabel} to clipboard:`, err);
    }

    if (input.parentNode) {
      input.parentNode.removeChild(input);
    }
  }

  return (
    <span className="cursor-pointer text-xs font-normal text-gray-label" onClick={copy}>
      &nbsp;&nbsp; {wasSuccessful ? 'Copied!' : 'Click to copy'}
    </span>
  );
}

export default CopyText;
