import { PersonalAutoPolicy, Policy } from './types';

import { Vehicle } from '@trellisconnect/types';
import { isPersonalAutoPolicy } from './helpers';

export function getUniqueVehicles(policies: Policy[]): Vehicle[] {
  const autoPolicies = policies.filter(pol => isPersonalAutoPolicy(pol)) as PersonalAutoPolicy[];
  const vehicles = autoPolicies.flatMap(pol => pol.vehicles);

  return [...new Map(vehicles.map(item => [item['vin'], item])).values()];
}
