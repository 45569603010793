import * as React from 'react';

import { Issuer, PersonalAutoPolicy, Policy, PropertyPolicy } from '../utils/types';
import { centsToCurrency, isPolicyActive } from '../lib/util';
import { isPersonalAutoPolicy, isPropertyPolicy } from '../utils/helpers';

import Card from './Card';
import CopyText from './copyText';
import FieldGroup from './FieldGroup';
import { PolicyHolder } from '@trellisconnect/types';
import SectionHeader from './SectionHeader';
import { StoreIssuers } from '../modules/issuers/redux';
import { StorePolicies } from '../modules/policies/redux';
import { checkUnknownValues } from '../utils';
import { connect } from 'react-redux';
import { getUniqueVehicles } from '../utils/vehicles';

interface AccountDetailsProps extends React.ComponentProps<'section'> {
  policies: Policy[];
  selectedPolicy: PersonalAutoPolicy | PropertyPolicy | null;
  issuers: Map<string, Issuer>;
}

function reducePolicies(policies: Policy[]) {
  return policies.reduce(
    (
      acc: {
        autoPolicies: Policy[];
        homePolicies: Policy[];
        activeAutoPolicies: Policy[];
        activeHomePolicies: Policy[];
        policyHolder?: PolicyHolder;
      },
      cur,
    ) => {
      if (isPropertyPolicy(cur)) {
        if (isPolicyActive(cur)) {
          acc.activeHomePolicies = [...acc.activeHomePolicies, cur];
        }
        acc.homePolicies = [...acc.homePolicies, cur];
      } else if (isPersonalAutoPolicy(cur)) {
        if (isPolicyActive(cur)) {
          acc.activeAutoPolicies = [...acc.activeAutoPolicies, cur];
        }
        acc.autoPolicies = [...acc.autoPolicies, cur];
      }

      if (cur.policyHolder && !acc.policyHolder) {
        acc.policyHolder = cur.policyHolder;
      }

      if (acc.policyHolder && !acc.policyHolder?.address) {
        if (cur.policyHolder?.address) {
          acc.policyHolder.address = cur.policyHolder.address;
        }

        if (isPropertyPolicy(cur)) {
          const propertyAddress = cur?.property?.address;
          if (propertyAddress) {
            acc.policyHolder.address = propertyAddress;
          }
        }
      }

      if (acc.policyHolder?.name && !acc.policyHolder.name?.firstName && cur.policyHolder?.name?.firstName) {
        acc.policyHolder.name.firstName = cur.policyHolder.name.firstName;
      }
      if (acc.policyHolder?.name && !acc.policyHolder.name?.lastName && cur.policyHolder?.name?.lastName) {
        acc.policyHolder.name.lastName = cur.policyHolder.name.lastName;
      }
      if (acc.policyHolder && !acc.policyHolder?.email && cur.policyHolder?.email) {
        acc.policyHolder.email = cur.policyHolder.email;
      }
      if (acc.policyHolder && !acc.policyHolder?.phoneNumber && cur.policyHolder?.phoneNumber) {
        acc.policyHolder.phoneNumber = cur.policyHolder.phoneNumber;
      }

      return acc;
    },
    {
      autoPolicies: [],
      homePolicies: [],
      activeAutoPolicies: [],
      activeHomePolicies: [],
    },
  );
}

function reducePremiums(policies: Policy[]) {
  return policies.reduce((acc, cur) => (cur.premiumCents ? acc + cur.premiumCents : acc), 0);
}

function reduceTermLength(policies: Policy[], defaultValue: number) {
  return policies.reduce((acc, currentPolicy) => currentPolicy?.policyTermMonths ?? acc, defaultValue);
}

function AccountDetails({ policies, selectedPolicy, issuers }: AccountDetailsProps) {
  const { autoPolicies, activeAutoPolicies, activeHomePolicies, policyHolder } = reducePolicies(policies);
  const hasAutoPolicy = Boolean(autoPolicies.length);
  const uniqueVehicles = getUniqueVehicles(autoPolicies);
  const policyIssuer = selectedPolicy?.issuer ? issuers.get(selectedPolicy?.issuer) : null;
  const issuerLabel = <span className="font-bold">{policyIssuer?.name || selectedPolicy?.issuer}</span>;
  const isMilitary = checkUnknownValues(selectedPolicy?.policyHolder?.isMilitary);
  const personalAutoTotalPremium = reducePremiums(activeAutoPolicies);
  const homeownersTotalPremium = reducePremiums(activeHomePolicies);
  const personalAutoTotalCurrency = personalAutoTotalPremium ? centsToCurrency(personalAutoTotalPremium, false) : null;
  const homeownersTotalCurrency = homeownersTotalPremium ? centsToCurrency(homeownersTotalPremium, false) : null;
  const autoTermLength = reduceTermLength(activeAutoPolicies, 6);
  const homeTermLength = reduceTermLength(activeHomePolicies, 12);
  const personalAutoMonthlyCurrency =
    personalAutoTotalPremium > 0 ? centsToCurrency(personalAutoTotalPremium / autoTermLength, false) : null;
  const homeownersMonthlyCurrency =
    homeownersTotalPremium > 0 ? centsToCurrency(homeownersTotalPremium / homeTermLength, false) : null;
  const totalMonthlyCurrency =
    personalAutoMonthlyCurrency && homeownersMonthlyCurrency
      ? centsToCurrency(homeownersTotalPremium / homeTermLength + personalAutoTotalPremium / autoTermLength, false)
      : null;

  return (
    <section data-cy="account-details">
      <SectionHeader>Account</SectionHeader>
      <Card>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div className="mx-4">
            <FieldGroup
              label="Name"
              value={
                policyHolder?.name?.firstName && policyHolder?.name?.lastName ? (
                  <span className="font-bold">{`${policyHolder.name.firstName} ${policyHolder.name.lastName}`}</span>
                ) : null
              }
            />
            <FieldGroup
              label="Email"
              value={
                policyHolder?.email ? (
                  <>
                    <span className="font-bold">{policyHolder.email.toLowerCase().toLowerCase()}</span>
                    <CopyText copyValue={policyHolder.email.toLowerCase()} notificationLabel="Email" />
                  </>
                ) : null
              }
            />
            <FieldGroup
              label="Phone"
              value={
                policyHolder?.phoneNumber ? (
                  <>
                    <span className="font-bold">
                      <a href={`tel:${policyHolder?.phoneNumber}`}>{policyHolder?.phoneNumber}</a>
                    </span>
                    <CopyText copyValue={policyHolder?.phoneNumber} notificationLabel="Phone" />
                  </>
                ) : null
              }
            />
            <FieldGroup
              label="Address"
              value={
                policyHolder?.address ? (
                  <>
                    <div>
                      <span className="font-bold">
                        {policyHolder?.address.number}{' '}
                        {policyHolder?.address.prefix
                          ? `${policyHolder?.address.prefix} ${policyHolder?.address.street}`
                          : policyHolder?.address.street}{' '}
                        {policyHolder?.address.type}
                        {policyHolder?.address.suffix && ` ${policyHolder?.address.suffix}`}
                      </span>
                    </div>
                    {policyHolder?.address.sec_unit_type || policyHolder?.address.sec_unit_num ? (
                      <div>
                        <span className="font-bold">
                          {policyHolder?.address.sec_unit_type} {policyHolder?.address.sec_unit_num}
                        </span>
                      </div>
                    ) : null}
                    <div>
                      <span className="font-bold">
                        {policyHolder?.address.city}, {policyHolder?.address.state} {policyHolder?.address.zip}
                      </span>
                    </div>
                  </>
                ) : null
              }
            />
          </div>

          <div className="mx-4">
            {personalAutoTotalCurrency ? (
              <FieldGroup
                label="Total Auto Premium"
                value={
                  <div className="flex items-center">
                    <span className="text-lg font-bold text-[#2d2e82]">{personalAutoTotalCurrency}</span>
                    {personalAutoMonthlyCurrency ? (
                      <span className="ml-1 font-bold">({personalAutoMonthlyCurrency}/mo)</span>
                    ) : null}
                  </div>
                }
              />
            ) : null}
            {homeownersTotalCurrency ? (
              <FieldGroup
                label="Total Home Premium"
                value={
                  <div className="flex items-center">
                    <span className="text-lg font-bold text-[#2d2e82]">{homeownersTotalCurrency}</span>
                    {homeownersMonthlyCurrency ? (
                      <span className="ml-1 font-bold">({homeownersMonthlyCurrency}/mo)</span>
                    ) : null}
                  </div>
                }
              />
            ) : null}
            {totalMonthlyCurrency ? (
              <FieldGroup
                label="Monthly Total"
                value={
                  <div className="flex items-center">
                    <span className="text-lg font-bold text-[#2d2e82]">{totalMonthlyCurrency}/mo</span>
                  </div>
                }
              />
            ) : null}
            {hasAutoPolicy ? (
              <FieldGroup
                label="Total Vehicles"
                value={
                  <span className="text-lg font-bold text-[#2d2e82]" data-cy="total-vehicles">
                    {uniqueVehicles ? uniqueVehicles.length : 0}
                  </span>
                }
              />
            ) : null}
            <FieldGroup label="Account Issuer" value={issuerLabel} />
            <FieldGroup label="Military" value={isMilitary} />
          </div>
        </div>
      </Card>
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies; issuers: StoreIssuers }) {
  return {
    issuers: state.issuers.list.data,
    policies: state.policies.list.data,
    selectedPolicy: state.policies.list.selectedAutoPolicy || state.policies.list.selectedPropertyPolicy,
  };
}

export default connect(mapStateToProps)(AccountDetails);
