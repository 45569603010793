export function checkIfLastDayOfMonth(year: number, month: number, day: number): boolean {
  if (day < 28) {
    return false;
  }

  let dayCount = 31;

  switch (month) {
    case 4:
    case 6:
    case 9:
    case 11:
      dayCount = 30;
      break;
    case 2:
      if (year % 4 === 0) {
        dayCount = 29;
      } else {
        dayCount = 28;
      }
      break;
  }

  return dayCount === day;
}

export function addMonths(date: Date, months: number) {
  const day = date.getDate();
  date.setMonth(date.getMonth() + months + 1, 0);
  date.setDate(Math.min(day, date.getDate()));
  return date;
}

export function reformatDateString(dateString: string | null | undefined): string | null {
  if (!dateString) {
    return null;
  }

  const incidentDate = new Date(dateString);

  return !isNaN(incidentDate.getTime())
    ? incidentDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        timeZone: 'UTC',
      })
    : null;
}
