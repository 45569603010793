type Env = {
  API_BASE_URL: string;
  ASSOCIATE_ACCOUNT_URL: string; // applicant
  // todo: append _URL to these names for clarity
  CHECK_ACCOUNT_RETRIEVAL_URL: string; // agent
  ACCOUNT_POLICIES_RETRIEVAL_URL: string; //agent & applicant
  ACCOUNT_INFO_RETRIEVAL_URL: string; // agent & applicant
  ISSUER_INFO_RETRIEVAL_URL: string; // agent & applicant
  INITIALIZE_ACCOUNT_RETRIEVAL_URL: string; // agent
  NODE_ENV: string;
  SEGMENT_KEY: string;
  UI_TYPE: UiType;
};
type UiType = 'agent' | 'applicant';

// Check for missing env vars
let errorMsg = '';

let env: Env = {
  API_BASE_URL: 'https://localhost',
  ASSOCIATE_ACCOUNT_URL: '',
  CHECK_ACCOUNT_RETRIEVAL_URL: '',
  ACCOUNT_POLICIES_RETRIEVAL_URL: '',
  INITIALIZE_ACCOUNT_RETRIEVAL_URL: 'https://localhost',
  ACCOUNT_INFO_RETRIEVAL_URL: '',
  ISSUER_INFO_RETRIEVAL_URL: '',
  NODE_ENV: processVar('NODE_ENV'),
  SEGMENT_KEY: '',
  UI_TYPE: 'agent',
};
// Don't use mock variables if tests aren't running
if (process && process.env.NODE_ENV !== 'test') {
  env = {
    NODE_ENV: processVar('NODE_ENV'),
    API_BASE_URL: processVar('REACT_APP_API_BASE_URL'),
    ASSOCIATE_ACCOUNT_URL: processVar('REACT_APP_ASSOCIATE_ACCOUNT_URL'),
    CHECK_ACCOUNT_RETRIEVAL_URL: processVar('REACT_APP_CHECK_ACCOUNT_RETRIEVAL_URL'),
    ACCOUNT_POLICIES_RETRIEVAL_URL: processVar('REACT_APP_ACCOUNT_POLICIES_RETRIEVAL_URL'),
    INITIALIZE_ACCOUNT_RETRIEVAL_URL: processVar('REACT_APP_INITIALIZE_ACCOUNT_RETRIEVAL_URL'),
    ACCOUNT_INFO_RETRIEVAL_URL: processVar('REACT_APP_ACCOUNT_INFO_RETRIEVAL_URL'),
    ISSUER_INFO_RETRIEVAL_URL: processVar('REACT_APP_ISSUER_INFO_RETRIEVAL_URL'),
    SEGMENT_KEY: processVar('REACT_APP_SEGMENT_KEY'),
    UI_TYPE: processVar('REACT_APP_UI_TYPE') as UiType,
  };
}

function processVar(key: string) {
  const value = process ? process.env[key] : null;

  if (!value) {
    console.log(`WARNING: ${key} is undefined`);
    errorMsg += `${key}\n`;
    return '';
  }

  if (key === 'UI_TYPE' && !['agent', 'applicant'].includes(value)) {
    console.log(`WARNING: UI_TYPE must be either 'agent' or 'applicant'`);
    errorMsg += `${key}\n`;
    return '';
  }

  return value;
}

if (errorMsg) throw new Error(`Issues with the following env vars: \n${errorMsg}`);

export default env;
