export const autoPolicyCoverageFields: { [index: string]: string } = {
  'Property Damage Liability': 'Property Damage Liability',
  'Bodily Injury Liability': 'Bodily Injury Liability',
  'Uninsured Motorist Bodily Injury': 'Uninsured Motorist Bodily Injury',
  'Personal Injury Protection': 'Personal Injury Protection',
  'Uninsured Motorist Property Damage': 'Uninsured Motorist Property Damage Limit',
  'Medical Payments': 'Medical Payments Limit',
  'Underinsured Motorist Bodily Injury': 'Underinsured Motorist Bodily Injury',
  'Accidental Death and Dismemberment': 'Accidental Death and Dismemberment Limit',
  'Funeral Benefits': 'Funeral Benefits Limit',
  'Optional Basic Economic Loss': 'Optional Basic Economic Loss Limit',
  'Road Trip Accident Accomodations': 'Road Trip Accident Accomodations Limit',
  'Guest Personal Injury Protection': 'Guest Personal Injury Protection',
};

export const propertyCoverageMapping: { [index: string]: string } = {
  Dwelling: 'Coverage A - Dwelling',
  'Other Structures': 'Coverage B - Other Structures',
  'Personal Property': 'Coverage C - Personal Property',
  'Loss of Use': 'Coverage D - Loss of Use',
  'Personal Liability': 'Coverage E - Personal Liability',
  'Medical Payments To Others': 'Coverage F - Medical Payments To Others',
};

export const vehicleCoverageFields: { [index: string]: string } = {
  Comprehensive: 'Comprehensive Deductible',
  Collision: 'Collision Deductible',
  'Collision Deductible Waiver': 'Collision Deductible Waiver',
  'Custom Parts and Equipment': 'Custom Parts and Equipment',
  'Loan/Lease Payoff': 'Loan/Lease Payoff',
  'Emergency Road Service': 'Emergency Road Service Limit',
  'Car Rental & Travel Expenses': 'Car Rental & Travel Expenses Limit',
  'Mechanical Breakdown': 'Mechanical Breakdown Deductible',
  'Underinsured Motorist Property Damage': 'Underinsured Motorist Property Damage Limit',
  'Rideshare Gap': 'Rideshare Gap',
  'Vanishing Deductible': 'Vanishing Deductible',
  'Comprehensive Glass': 'Comprehensive Glass Deductible',
  'Property Protection Insurance': 'Property Protection Insurance',
  'Limited Property Damage': 'Limited Property Damage',
};

export const ownershipStatusDisplayValues: { [index: string]: string } = {
  financed: 'Financed',
  financed_or_leased: 'Financed or Leased',
  leased: 'Leased',
  owned: 'Owned',
};

export const claimTypes: { [index: string]: string } = {
  TICKET: 'Ticket',
  DUI: 'DUI',
  ACCIDENT: 'Accident',
  CLAIM: 'Claim',
  SUSPENSION: 'Suspension',
};

export enum PolicyStatus {
  CANCELED = 'Canceled',
  INACTIVE = 'Inactive',
  EXPIRED = 'Expired',
  FUTURE = 'Future',
}

export const RESIDENCE_STATUS: { [index: string]: string } = {
  OwnSingleFamily: 'Own - Single Family',
  OwnMobileHome: 'Own - Mobile Home',
  LiveWithParents: 'Live With Parents',
  Rent: 'Rent',
};
