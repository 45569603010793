import * as React from 'react';

import { Store, history } from '../modules/rootReducer';

import AlertBox from './AlertBox';
import CopyText from './copyText';
import Spinner from './Spinner';
import { clearApplicants } from '../modules/applicants/redux';
import { clearPolicies } from '../modules/policies';
import { connect } from 'react-redux';
import { dispatch } from '../store';

const APPLICANT_URL = 'https://secure-link.trellisconnect.com';
const AGENT_URL = 'https://agent.trellisconnect.com';

type PageProps = {
  partnerKey: string;
  requestId: string;
  phoneNumber: string;
  agentEmail: string;
};

function WaitingForApplicant({ partnerKey, requestId, phoneNumber, agentEmail }: PageProps) {
  const startTime = new Date();

  function goBackToStart() {
    history.replace({ search: '' });
    dispatch(clearApplicants());
    dispatch(clearPolicies());
  }

  let hours = startTime.getHours();
  let amOrPm = 'AM';
  if (hours >= 12) {
    amOrPm = 'PM';
  }
  if (hours > 12) {
    hours -= 12;
  }

  const time = `${hours}:${String(startTime.getMinutes()).padStart(2, '0')} ${amOrPm}`;

  const date = `${startTime.toLocaleString('en-US', {
    month: 'long',
  })} ${startTime.getDate()}, ${startTime.getFullYear()}`;

  const sentSMS = Boolean(phoneNumber);
  const uParam = requestId;
  const applicantLink = `${APPLICANT_URL}/?u=${uParam}&p=${partnerKey}&a=${agentEmail}`;
  const agentLink = `${AGENT_URL}/${partnerKey}?u=${uParam}`;

  return (
    <div className="flex justify-center items-center flex-col m-auto max-w-lg text-center">
      <div className="mb-4">
        <svg width="57" height="68" viewBox="0 0 57 68" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M41 45V63C41 64.6569 39.6569 66 38 66H5C3.34315 66 2 64.6569 2 63V5C2 3.34315 3.34315 2 5 2H38C39.6569 2 41 3.34315 41 5V8"
            stroke="#2D2E82"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <rect x="16" y="58" width="11" height="3" rx="1.5" fill="#2D2E82" />
          <rect x="26" y="22" width="23" height="3" rx="1.5" fill="#2D2E82" />
          <path
            d="M50 14H25C22.2386 14 20 16.2386 20 19V34C20 36.7614 22.2386 39 25 39H27.95C28.1157 39 28.25 39.1343 28.25 39.3V45.352C28.25 45.6076 28.5492 45.7461 28.7441 45.5808L35.1005 40.1875C36.004 39.4208 37.1505 39 38.3354 39H50C52.7614 39 55 36.7614 55 34V19C55 16.2386 52.7614 14 50 14Z"
            stroke="#2D2E82"
            strokeWidth="3"
          />
          <rect x="26" y="29" width="23" height="3" rx="1.5" fill="#2D2E82" />
        </svg>
      </div>

      {sentSMS && (
        <>
          <p className="mb-2 font-bold text-xl text-center">Request sent by text to {phoneNumber}</p>
          <div className="mb-6 opacity-70 text-sm text-[#4f4f4f]">
            On {date} at {time}
          </div>
        </>
      )}

      {!sentSMS && (
        <>
          {requestId ? (
            <>
              <p className="mb-6 font-bold text-xl text-center">Send link to customer</p>
              <p>
                <b>{applicantLink}</b> <CopyText copyValue={applicantLink} notificationLabel="ApplicantLink" />
              </p>
            </>
          ) : (
            <p className="mb-6 font-bold text-xl text-center">Generating link...</p>
          )}
          <Spinner />
        </>
      )}

      <AlertBox type="warning">
        <div className="flex items-center">
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="clock-circle"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
            className="mr-4 text-yellow-500"
          >
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm176.5 585.7l-28.6 39a7.99 7.99 0 0 1-11.2 1.7L483.3 569.8a7.92 7.92 0 0 1-3.3-6.5V288c0-4.4 3.6-8 8-8h48.1c4.4 0 8 3.6 8 8v247.5l142.6 103.1c3.6 2.5 4.4 7.5 1.8 11.1z"></path>
          </svg>
          <div className="text-[#faad14] text-left">
            Wait here until the caller shares permission to their policy
            <br />
            Use this link to get back to this page:
            <br />
            <a href={agentLink}>{agentLink}</a>
            <CopyText copyValue={agentLink} notificationLabel="AgentLink" />
          </div>
        </div>
      </AlertBox>

      <div className="my-6">- or -</div>

      {sentSMS && (
        <div className="underline cursor-pointer" onClick={goBackToStart}>
          Go back to resend or try a different number
        </div>
      )}
      {!sentSMS && (
        <div className="underline cursor-pointer" onClick={goBackToStart}>
          Go back to generate a new link
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state: Store) {
  return {
    partnerKey: state.agent.data.partner.key,
    requestId: state.applicants.data.requestId,
    phoneNumber: state.applicants.data.phoneNumber,
    agentEmail: state.agent.data.email,
  };
}

export default connect(mapStateToProps)(WaitingForApplicant);
