import * as React from 'react';

import { CardItem, PersonalAutoPolicy } from '../../utils/types';
import { autoPolicyCoverageFields, ownershipStatusDisplayValues, vehicleCoverageFields } from '../../utils/mappings';
import { formatPersonalAutoCoverage, sortCoveragesByLabel } from '../../utils/coverages';

import CopyText from '../copyText';
import SectionHeader from '../SectionHeader';
import { StorePolicies } from '../../modules/policies/redux';
import StructuredCard from '../StructuredCard';
import { Vehicle } from '@trellisconnect/types';
import { capitalize } from '../../utils/helpers';
import { connect } from 'react-redux';
import { formatAddress } from '../../utils';

interface VehicleDetailProps extends React.ComponentProps<'section'> {
  selectedPolicy: PersonalAutoPolicy | null;
}

function getVehicleItems(vehicle: Vehicle): CardItem[] {
  const vehicleCoverages = (vehicle.coverages ?? []).filter(coverage => !autoPolicyCoverageFields[coverage.name]);
  const vehInfo: CardItem[] = vehicleCoverages
    .map(coverage => ({
      label: vehicleCoverageFields[coverage.name] ?? coverage.name,
      value: formatPersonalAutoCoverage(coverage),
    }))
    .sort(sortCoveragesByLabel);

  if (vehicle?.lienHolder) {
    vehInfo.push({
      label: 'Lien Holder',
      value: vehicle.lienHolder,
    });
  }
  if (vehicle?.lienHolderAddress) {
    const address = formatAddress(vehicle.lienHolderAddress);

    if (address) {
      vehInfo.push({
        label: 'Lien Holder Address',
        value: <div>{address}</div>,
      });
    }
  }
  if (vehicle?.ownershipStatus) {
    const ownershipStatus = ownershipStatusDisplayValues[vehicle.ownershipStatus];

    if (ownershipStatus) {
      vehInfo.push({
        label: 'Ownership Status',
        value: ownershipStatus,
      });
    }
  }

  if (vehicle?.annualMileage) {
    vehInfo.push({
      label: 'Annual Mileage',
      value: `${vehicle.annualMileage.toLocaleString()} miles`,
    });
  }

  if (vehicle?.garagingLocationRaw) {
    const garagingAddress = formatAddress(vehicle.garagingLocation) || vehicle.garagingLocationRaw;
    vehInfo.push({
      label: 'Garaging Location',
      value: <div>{garagingAddress}</div>,
    });
  }

  if (vehicle?.use) {
    vehInfo.push({
      label: 'Vehicle Use',
      value: <p>{capitalize(vehicle?.use)}</p>,
    });
  }

  return vehInfo;
}

function VehicleDetails({ selectedPolicy }: VehicleDetailProps) {
  const vehicles = selectedPolicy?.vehicles;

  if (!selectedPolicy || !vehicles?.length) {
    return null;
  }

  return (
    <section className="w-full" data-cy="vehicles-section">
      <SectionHeader>Vehicles</SectionHeader>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {vehicles.map((v, i) => (
          <StructuredCard
            key={v.vin || i}
            data-cy="vehicle"
            label={
              <>
                {v.year} {v.make} {v.model} ({v.vin})
                {v.vin ? <CopyText copyValue={v.vin} notificationLabel="VIN" /> : null}
              </>
            }
            items={getVehicleItems(v)}
          />
        ))}
      </div>
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies }) {
  return {
    selectedPolicy: state.policies.list.selectedAutoPolicy,
  };
}

export default connect(mapStateToProps)(VehicleDetails);
