import { Action, AsyncState } from '../../util';
import { Store, history } from '../../rootReducer';
import { call, cancel, delay, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { PartnerInfo } from '../../../lib/partners';
import { SagaIterator } from 'redux-saga';
import { env } from '../../../lib';
import { getPolicies } from '../../policies';
import localDb from '../../../lib/localDb';
import request from '../../../lib/request';

const POLLING_INTERVAL_MS = 2000;

// REQUEST

const REQUEST_INIT = 'applicants/REQUEST';
const REQUEST_PENDING = 'applicants/REQUEST_PENDING';
const REQUEST_SUCCESS = 'applicants/REQUEST_SUCCESS';
const REQUEST_FAILURE = 'applicants/REQUEST_FAILURE';

type RequestInitAction = {
  type: typeof REQUEST_INIT;
  payload: string;
};
type RequestPendingAction = {
  type: typeof REQUEST_PENDING;
  payload: RequestPendingActionPayload;
};
type RequestPendingActionPayload = {
  accountId?: string;
  requestId?: string;
  phoneNumber?: string;
};
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS;
  payload: string;
};
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE;
  payload: Error;
};

export function requestAccount(phoneNumber: string): RequestInitAction {
  return {
    type: REQUEST_INIT,
    payload: phoneNumber,
  };
}
export function requestAccountPending(dataUpdate: RequestPendingActionPayload): RequestPendingAction {
  return {
    type: REQUEST_PENDING,
    payload: dataUpdate,
  };
}
export function requestAccountSuccess(accountId: string): RequestSuccessAction {
  return {
    type: REQUEST_SUCCESS,
    payload: accountId,
  };
}
function requestAccountFailure(error: Error): RequestFailureAction {
  return {
    type: REQUEST_FAILURE,
    payload: error,
  };
}

type InitializeAPIPayload = {
  clientId: string;
  partner: string;
  phoneNumber: string;
  agentEmail: string;
};
type InitializeApiResponse = InitializeApiResponseFailure | InitializeApiResponseSuccess;
type InitializeApiResponseFailure = {
  error: string;
  success: false;
};
type InitializeApiResponseSuccess = {
  data: {
    requestId: string;
    smsApplicant: boolean;
  };
  error: string;
  success: true;
};

type StatusAPIPayload = {
  clientId: string;
  requestId?: string;
};
type StatusApiResponse = StatusApiResponseFailure | StatusApiResponseSuccess;
type StatusApiResponseFailure = {
  error: string;
  success: false;
};
type StatusApiResponseSuccess = {
  data: {
    accountId: string;
  };
  error: string;
  success: true;
};

export function* requestAccountTask(action: RequestInitAction): SagaIterator {
  yield put(requestAccountPending({ phoneNumber: action.payload }));

  const partnerInfo: PartnerInfo = yield select((state: Store) => state.agent.data.partner);

  try {
    // @ts-ignore
    const agent = yield localDb.getItem('agent');
    const initializeApiPayload: InitializeAPIPayload = {
      clientId: partnerInfo.apiKey,
      partner: partnerInfo.key,
      phoneNumber: action.payload,
      agentEmail: agent && agent.email,
    };
    const initializeResponse: InitializeApiResponse = yield call(
      request.post,
      env.INITIALIZE_ACCOUNT_RETRIEVAL_URL,
      initializeApiPayload,
    );

    if (!initializeResponse.success) {
      throw new Error(initializeResponse.error);
    }

    const { requestId, smsApplicant } = initializeResponse.data;
    yield put(requestAccountPending({ requestId }));

    analytics.track(`${smsApplicant ? 'SMS' : 'URL'} Applicant Account Request`, {
      requestId,
      partner: partnerInfo.key,
      agentEmail: agent && agent.email,
    });

    history.push(`${window.location.pathname}?u=${requestId}`);
    const pollAccTaskInstance = yield fork(pollAccountTask, requestId, partnerInfo);
    yield take(CLEAR);
    yield cancel(pollAccTaskInstance);
  } catch (err) {
    yield put(requestAccountFailure(err as Error));
  }
}

export function* pollAccountTask(requestId: string, partnerInfo: PartnerInfo): SagaIterator {
  try {
    let accountId = '';
    while (!accountId) {
      const statusApiPayload: StatusAPIPayload = {
        clientId: partnerInfo.apiKey,
        requestId,
      };
      const statusResponse: StatusApiResponse = yield call(
        request.post,
        env.CHECK_ACCOUNT_RETRIEVAL_URL,
        statusApiPayload,
      );

      if (!statusResponse.success) {
        throw new Error(statusResponse.error);
      }
      if (statusResponse.data.accountId) {
        accountId = statusResponse.data.accountId;
      }

      yield delay(POLLING_INTERVAL_MS);
    }

    yield put(requestAccountSuccess(accountId));
    yield put(getPolicies(accountId, partnerInfo.apiKey));
    history.push(`${window.location.pathname}/${accountId}`);
  } finally {
    yield put(requestAccountFailure(new Error('Cancelled by user')));
  }
}

export function* pollAccountTaskFromLink(requestId: string, partnerInfo: PartnerInfo): SagaIterator {
  yield put(requestAccountPending({ requestId }));
  const pollAccTaskInstance = yield fork(pollAccountTask, requestId, partnerInfo);
  yield take(CLEAR);
  yield cancel(pollAccTaskInstance);
}

export function* requestAccountWatcher(): SagaIterator {
  yield takeEvery(REQUEST_INIT, requestAccountTask);
}

// CLEAR

const CLEAR = 'applicants/list/CLEAR';

type ClearApplicantsAction = {
  type: typeof CLEAR;
};

export function clearApplicants(): ClearApplicantsAction {
  return { type: CLEAR };
}

// REDUCER

type StoreApplicants = {
  data: {
    accountId: string;
    phoneNumber: string;
    requestId: string;
  };
  request: AsyncState;
};

const initialState: StoreApplicants = {
  data: {
    accountId: '',
    phoneNumber: '',
    requestId: '',
  },
  request: {
    error: null,
    isLoading: false,
  },
};

export function applicantsReducer(state: StoreApplicants = initialState, action: Action): StoreApplicants {
  switch (action.type) {
    // REQUEST
    case REQUEST_PENDING:
      return {
        ...state,
        request: { isLoading: true, error: null },
        data: { ...state.data, ...action.payload },
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        request: { isLoading: false, error: null },
        data: { ...state.data, accountId: action.payload },
      };
    case REQUEST_FAILURE:
      return { ...state, request: { isLoading: false, error: action.payload } };

    // CLEAR
    case CLEAR:
      return initialState;

    default:
      return state;
  }
}
