import 'whatwg-fetch';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import * as React from 'react';
import * as Sentry from '@sentry/react';

import App from './components';
import { BrowserTracing } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import FiveHundred from './components/500';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { history } from './modules/rootReducer';
import store from './store';

Sentry.init({
  dsn: 'https://a2002e7d98304b4383fae576f0286b27@o268779.ingest.sentry.io/1774805',
  denyUrls: [/test-ui-agent\.firebaseapp\.com/i, /test-ui-agent\.web\.app/],
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['agent.trellisconnect.com', /^\//],

      // Can also use reactRouterV3Instrumentation or reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  normalizeDepth: 10,
});

const rootElement = document.getElementById('root');
const rootComponent = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Sentry.ErrorBoundary fallback={<FiveHundred />}>
        <App />
      </Sentry.ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(rootComponent, rootElement);
