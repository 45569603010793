import { call, put, takeEvery } from 'redux-saga/effects';
import localDb, { DeviceData } from '../../../lib/localDb';
import { pollAccountTaskFromLink, requestAccountSuccess } from '../../applicants/redux';

import { Action } from '../../util';
import { SagaIterator } from 'redux-saga';
import { getPartnerInfoByNameOrPath } from '../../../lib/partners';
import { getPolicies } from '../../policies';
import sharedRequest from '../../../lib/request';
import { updateAgentData } from '../../agent';
import uuid from 'uuid/v4';

const INIT = 'app/bootstrap';
const PENDING = 'app/bootstrap/PENDING';
const SUCCESS = 'app/bootstrap/SUCCESS';
const FAILURE = 'app/bootstrap/FAILURE';

type InitAction = {
  type: typeof INIT;
};
type PendingAction = {
  type: typeof PENDING;
};
type SuccessAction = {
  type: typeof SUCCESS;
};
type FailureAction = {
  type: typeof FAILURE;
  payload: Error;
};

export function bootstrap(): InitAction {
  return { type: INIT };
}
function bootstrapPending(): PendingAction {
  return { type: PENDING };
}
function bootstrapSuccess(): SuccessAction {
  return { type: SUCCESS };
}
function bootstrapFailure(error: Error): FailureAction {
  return {
    type: FAILURE,
    payload: error,
  };
}

function* bootstrapTask(): SagaIterator {
  yield put(bootstrapPending());

  try {
    // Try and get device ID out of local storage
    let deviceId: string = '';
    try {
      deviceId = (yield call(localDb.getItem, 'device')) as unknown as DeviceData;
    } catch (e) {
      console.log('Unable to GET device ID from local storage');
    }
    // Generate a device ID and store if there isn't one
    if (!deviceId) {
      deviceId = uuid();
      try {
        yield call([localDb, localDb.setItem], 'device', deviceId);
      } catch (e) {
        console.log(e);
        console.log('Unable to SET device ID to local storage');
      }
    }

    // Get partner information based off pathname
    const partnerInfo = getPartnerInfoByNameOrPath(window.location.pathname);
    if (!partnerInfo.apiKey) {
      throw new Error(`Unable to find partner based on pathname: '${window.location.pathname}'`);
    }

    // Set up shared request lib
    sharedRequest.setup(partnerInfo.apiKey);

    // Get Applicant account ID from URL if Agent has refreshed the page
    const accountId = window.location.pathname.split('/')[2];
    if (accountId) {
      yield put(requestAccountSuccess(accountId));
      yield put(getPolicies(accountId, partnerInfo.apiKey));
    }

    analytics.identify(deviceId);
    analytics.page('Landing', { partner: partnerInfo.key });

    const agentData = {
      deviceId,
      partner: partnerInfo,
    };

    yield put(updateAgentData(agentData));
    yield put(bootstrapSuccess());

    const params = new URLSearchParams(document.location.search);
    // Check for request id (u for user)
    const requestId = params.get('u') || '';
    if (requestId && window.location.pathname.length < 3) {
      yield call(pollAccountTaskFromLink, requestId, partnerInfo);
    }
  } catch (err) {
    yield put(bootstrapFailure(err as Error));
  }
}

export function* bootstrapWatcher(): SagaIterator {
  yield takeEvery(INIT, bootstrapTask);
}

type StoreAppBootstrap = {
  error: string | null;
  isDone: boolean;
};
const initialState: StoreAppBootstrap = {
  error: null,
  isDone: false,
};

export function bootstrapReducer(state: StoreAppBootstrap = initialState, action: Action): StoreAppBootstrap {
  switch (action.type) {
    case SUCCESS:
      return {
        error: null,
        isDone: true,
      };

    case FAILURE:
      return {
        error: action.payload.message,
        isDone: true,
      };

    default:
      return state;
  }
}
