import { Action, AsyncState } from '../../util';
import { call, put, takeEvery } from 'redux-saga/effects';

import { SagaIterator } from 'redux-saga';
import env from '../../../lib/env';
import request from '../../../lib/request';

// GET

const GET_INIT = 'issuers/list/GET';
const GET_PENDING = 'issuers/list/GET_PENDING';
const GET_SUCCESS = 'issuers/list/GET_SUCCESS';
const GET_FAILURE = 'issuers/list/GET_FAILURE';

type GetInitAction = {
  type: typeof GET_INIT;
  apiKey: string;
  payload: string;
};
type GetPendingAction = {
  type: typeof GET_PENDING;
};
type GetSuccessAction = {
  type: typeof GET_SUCCESS;
  payload: Issuer[];
};
type GetFailureAction = {
  type: typeof GET_FAILURE;
  payload: Error;
};

export function getIssuer(accountId: string, apiKey: string): GetInitAction {
  return {
    type: GET_INIT,
    apiKey: apiKey,
    payload: accountId,
  };
}
function getIssuerPending(): GetPendingAction {
  return { type: GET_PENDING };
}
function getIssuerSuccess(issuers: Issuer[]): GetSuccessAction {
  return {
    type: GET_SUCCESS,
    payload: issuers,
  };
}
function getIssuerFailure(error: Error): GetFailureAction {
  return {
    type: GET_FAILURE,
    payload: error,
  };
}

type IssuerApiResponse = {
  error: string;
  success: false;
  data: Issuer[];
};

type IssuerApiPayload = {
  clientId: string;
};

export function* getIssuerTask(action: GetInitAction): SagaIterator {
  yield put(getIssuerPending());

  try {
    const accountId = action.payload;
    if (!accountId) throw new Error('Unable to get issuers. No accountId');

    // Get the issuer
    const issuerApiPayload: IssuerApiPayload = {
      clientId: action.apiKey,
    };
    const apiIssuerResponse: IssuerApiResponse = yield call(
      request.post,
      env.ISSUER_INFO_RETRIEVAL_URL,
      issuerApiPayload,
    );

    yield put(getIssuerSuccess(apiIssuerResponse.data));
  } catch (err) {
    yield put(getIssuerFailure(err as Error));
  }
}

export function* getIssuerWatcher(): SagaIterator {
  yield takeEvery(GET_INIT, getIssuerTask);
}

// REDUCER

export type Issuers = Map<string, Issuer>;
type Issuer = {
  color: string; // hex code
  id: string;
  name: string;
  site: string;
  subIssuers?: SubIssuer[];
};
type SubIssuer = {
  accountLabel: string; // what accounts are called (like "User ID")
  color: string; // hex code
  id: string;
  name: string;
  site: string;
};

type StoreIssuers = {
  data: Issuers;
  get: AsyncState;
};
const initialState: StoreIssuers = {
  data: new Map(),
  get: {
    error: null,
    isLoading: false,
  },
};

export function listReducer(state: StoreIssuers = initialState, action: Action): StoreIssuers {
  switch (action.type) {
    // GET
    case GET_PENDING:
      return { ...state, get: { isLoading: true, error: null } };

    case GET_SUCCESS:
      const newData = new Map(state.data);
      for (const issuer of action.payload) {
        newData.set(issuer.id, issuer);
      }
      return {
        ...state,
        get: { isLoading: false, error: null },
        data: newData,
      };

    case GET_FAILURE:
      return { ...state, get: { isLoading: false, error: action.payload } };

    default:
      return state;
  }
}
