import { CardItem, PersonalAutoPolicy, Policy, PropertyPolicy } from './types';

import { PolicyTypes } from '@trellisconnect/types';

export const isTruthy = <T>(input: T | null | undefined): input is T => Boolean(input);

export function getItem(
  label: string,
  value?: string | JSX.Element | null,
  hint?: string | JSX.Element,
): CardItem | null {
  if (!hint) hint = '';
  if (!value) {
    return null;
  }

  return {
    label,
    value,
    hint,
  };
}

export function isPersonalAutoPolicy(policy: PersonalAutoPolicy | Policy): policy is PersonalAutoPolicy {
  return (
    (policy as PersonalAutoPolicy).vehicles !== undefined ||
    !policy?.policyType ||
    policy?.policyType === PolicyTypes.PERSONAL_AUTO
  );
}

export function isPropertyPolicy(policy: PropertyPolicy | Policy): policy is PropertyPolicy {
  return (
    (policy as PropertyPolicy).property !== undefined ||
    policy?.policyType === PolicyTypes.HOMEOWNERS ||
    policy?.policyType === PolicyTypes.RENTERS ||
    policy?.policyType === PolicyTypes.CONDO ||
    policy?.policyType === PolicyTypes.MOBILE_HOME
  );
}

export function capitalize(word?: string | null) {
  if (!word) return word;
  return word[0]?.toUpperCase() + word?.substring(1).toLowerCase();
}

export function isNumber(value: any) {
  return typeof value === 'number' && isFinite(value);
}

export function throttle(cb: (args: string[]) => void, delay = 250) {
  let shouldWait = false;
  let waitingArgs: any;
  const timeoutFunc = () => {
    if (waitingArgs == null) {
      shouldWait = false;
    } else {
      cb([...waitingArgs]);
      waitingArgs = null;
      setTimeout(timeoutFunc, delay);
    }
  };

  return (...args: any) => {
    if (shouldWait) {
      waitingArgs = args;
      return;
    }

    cb([...args]);
    shouldWait = true;
    setTimeout(timeoutFunc, delay);
  };
}
