import FetchingPolicy from './FetchingPolicy';
import PhoneSearch from './PhoneSearch';
import PolicyFetchFailure from './PolicyFetchFailure';
import PolicyList from './PolicyList';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Spinner from './Spinner';
import { Store } from '../modules/rootReducer';
import WaitingForApplicant from './WaitingForApplicant';
import { connect } from 'react-redux';

type OwnProps = {
  areTherePolicies: boolean;
  bootstrapError: string | null;
  hasPolicyFetchError: boolean;
  hasPolicyFetchRun: boolean;
  isBootstrapDone: boolean;
  isWaitingForPolicy: boolean;
  isWaitingOnApplicant: boolean;
  accountId: string | null;
};

type MatchProps = {
  id: string;
};

type Props = OwnProps & RouteComponentProps<MatchProps>;

const AgentTool: React.FC<Props> = props => {
  if (!props.isBootstrapDone) return <Spinner />;

  return (
    <>
      {!props.areTherePolicies &&
        !props.isWaitingOnApplicant &&
        !props.isWaitingForPolicy &&
        !props.hasPolicyFetchRun &&
        !props.accountId &&
        !props.accountId && <PhoneSearch />}
      {!props.areTherePolicies && props.isWaitingOnApplicant && !props.accountId && <WaitingForApplicant />}
      {!props.areTherePolicies && props.isWaitingForPolicy && props.accountId && <FetchingPolicy />}
      {props.areTherePolicies && <PolicyList />}
      {!props.areTherePolicies &&
        !props.isWaitingOnApplicant &&
        !props.isWaitingForPolicy &&
        props.hasPolicyFetchRun &&
        (props.accountId || props.hasPolicyFetchError) && <PolicyFetchFailure />}
      {props.hasPolicyFetchError}
    </>
  );
};

function mapStateToProps(state: Store) {
  return {
    accountId: state.applicants.data.accountId,
    areTherePolicies: !!state.policies.list.data.length,
    bootstrapError: state.app.bootstrap.error,
    isBootstrapDone: state.app.bootstrap.isDone,
    isWaitingForPolicy: state.policies.list.get.isLoading,
    isWaitingOnApplicant: state.applicants.request.isLoading,
    hasPolicyFetchError: !!state.policies.list.get.error,
    hasPolicyFetchRun: state.policies.list.get.hasRun,
  };
}

export default connect(mapStateToProps)(AgentTool);
