import * as React from 'react';

export default function Spinner() {
  return (
    <div className="flex flex-col h-6 justify-between animate-spin mb-6">
      <div className="flex justify-between w-6">
        <i className="w-2 h-2 inline-block rounded-full bg-gray-400"></i>
        <i className="w-2 h-2 inline-block rounded-full bg-gray-400"></i>
      </div>
      <div className="flex justify-between w-6">
        <i className="w-2 h-2 inline-block rounded-full bg-gray-400"></i>
        <i className="w-2 h-2 inline-block rounded-full bg-gray-400"></i>
      </div>
    </div>
  );
}
