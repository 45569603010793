import * as React from 'react';

import AlertBox from './AlertBox';
import { PolicyDocument } from '../utils/types';
import { StoreAgent } from '../modules/agent/redux';
import classNames from 'classnames';
import { connect } from 'react-redux';
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';
import { useInterval } from 'react-use';

type PolicyDocumentProps = {
  documents: PolicyDocument[];
  partnerKey: string;
};

type ButtonProps = {
  url: string;
  label: string;
  disabled: boolean;
  partnerKey: string;
};

const buttonLabel = {
  DEC_PAGE: 'Declarations Page',
};

const PolicyDocButton = ({ url, label, disabled, partnerKey }: ButtonProps) => {
  const trackClick = () =>
    analytics.track('Opened policy document', {
      document: label,
      partner: partnerKey,
    });
  return (
    <a
      className={classNames('rounded-full py-2 px-4 text-lg flex items-center transition-all', {
        'bg-trellis-blue hover:bg-gray-900 hover:text-white text-white cursor-pointer': !disabled,
        'bg-gray-300 text-gray-600 cursor-not-allowed pointer-events-none': disabled,
      })}
      href={url}
      onClick={trackClick}
      target="_blank"
      rel="noreferrer"
    >
      {label}
      <svg
        className="ml-4"
        viewBox="64 64 896 896"
        focusable="false"
        data-icon="arrow-right"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 0 0 0-48.4z"></path>
      </svg>
    </a>
  );
};

function PolicyDocuments({ documents, partnerKey }: PolicyDocumentProps) {
  const calcState = () =>
    documents.map(({ urlExpiration, type, ...other }) => ({
      disabled: isPast(parseISO(urlExpiration)),
      label: buttonLabel[type] || type,
      partnerKey: partnerKey,
      ...other,
    }));

  const [buttonsState, setButtonsState] = React.useState(calcState());
  useInterval(() => setButtonsState(calcState()), 100);

  const renderDocumentButton = (state: ButtonProps) => <PolicyDocButton key={state.url} {...state} />;

  return (
    <div className="flex flex-col policy-doc-wrap">
      <div className="flex justify-center policy-button-wrap mb-4">{buttonsState.map(renderDocumentButton)}</div>
      {buttonsState?.some(state => state.disabled) ? (
        <AlertBox type="info">Some document links have expired. Please refresh the page to renew them.</AlertBox>
      ) : null}
    </div>
  );
}

function mapStateToProps(state: { agent: StoreAgent }) {
  return {
    partnerKey: state.agent.data.partner.key,
  };
}

export default connect(mapStateToProps)(PolicyDocuments);
