import * as React from 'react';

import Card from './Card';
import { Discount } from '@trellisconnect/types';
import SectionHeader from './SectionHeader';
import { StorePolicies } from '../modules/policies/redux';
import { connect } from 'react-redux';

interface DiscountDetailProps extends React.ComponentProps<'section'> {
  discounts?: Discount[];
}

function filterDiscounts(discounts?: Discount[]) {
  return discounts?.length
    ? discounts
        .filter(d => d.name && d.name !== 'Other')
        .reduce((acc: string[], ds: { name: string }) => (!acc.includes(ds.name) ? [...acc, ds.name] : acc), [])
    : [];
}

function DiscountDetails({ discounts }: DiscountDetailProps) {
  const filteredPolicyDiscounts = filterDiscounts(discounts);

  if (!filteredPolicyDiscounts?.length) {
    return null;
  }

  return (
    <section className="w-full" data-cy="discounts-section">
      <SectionHeader>Discounts</SectionHeader>

      <Card>
        <p className="mb-2 font-bold">Policy Discounts</p>

        <ul>
          {filteredPolicyDiscounts.map((discountName: string) => (
            <li key={discountName}>{discountName}</li>
          ))}
        </ul>
      </Card>
    </section>
  );
}

function mapStateToProps(state: { policies: StorePolicies }) {
  const selectedPolicy = state.policies.list.selectedAutoPolicy || state.policies.list.selectedPropertyPolicy;
  return {
    discounts: selectedPolicy?.discounts,
  };
}

export default connect(mapStateToProps)(DiscountDetails);
