import * as React from 'react';

import AccountDetails from './AccountDetails';
import AutoPolicyDetails from './auto/AutoPolicyDetails';
import DiscountDetails from './DiscountDetails';
import DocumentsSection from './Documents';
import DriverDetails from './auto/DriverDetails';
import HouseholdMemberDetails from './home/HouseholdMemberDetails';
import IncidentDetails from './auto/IncidentDetails';
import MortgageDetails from './home/MortgageDetails';
import { Policy } from '../utils/types';
import PolicyMenu from './PolicyMenu';
import PropertyDetails from './home/PropertyDetails';
import PropertyPolicyDetails from './home/PropertyPolicyDetails';
import ScrollToTopButton from './scrollToTopButton';
import { StoreAgent } from '../modules/agent/redux';
import { StorePolicies } from '../modules/policies/redux';
import VehicleDetails from './auto/VehicleDetails';
import { connect } from 'react-redux';

type PolicyListProps = {
  isLoading: boolean;
  policies: Policy[];
  partnerKey: string;
  agentEmail: string;
  dateRetrieved: string | null | undefined;
};

function PolicyList({ policies, dateRetrieved, isLoading, partnerKey, agentEmail }: PolicyListProps) {
  React.useEffect(() => {
    analytics.page('Applicant Policy', {
      partner: partnerKey,
      agentEmail,
    });
  }, [partnerKey, agentEmail]);

  if (!policies?.length || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl px-4 mx-auto w-full">
      <AccountDetails />
      <PolicyMenu />

      <div className="w-full">
        <DocumentsSection />
        <AutoPolicyDetails />
        <PropertyPolicyDetails />
        <DriverDetails />
        <VehicleDetails />
        <PropertyDetails />
        <MortgageDetails />
        <HouseholdMemberDetails />
        <IncidentDetails />
        <DiscountDetails />
        {dateRetrieved ? (
          <footer className="py-8 text-center">
            Data last refreshed at: {new Date(dateRetrieved).toLocaleString()}
          </footer>
        ) : null}
      </div>

      <ScrollToTopButton />
    </div>
  );
}

function mapStateToProps(state: { agent: StoreAgent; policies: StorePolicies }) {
  const selectedPolicy = state.policies.list.selectedAutoPolicy || state.policies.list.selectedPropertyPolicy;
  return {
    isLoading: state.policies.list.get.isLoading,
    policies: state.policies.list.data,
    partnerKey: state.agent.data.partner.key,
    agentEmail: state.agent.data.email,
    dateRetrieved: selectedPolicy?.dateRetrieved,
  };
}

export default connect(mapStateToProps)(PolicyList);
