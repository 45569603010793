import * as React from 'react';

import Card from './Card';
import { CardItem } from '../utils/types';
import FieldGroup from './FieldGroup';

type StructuredCardProps = {
  label?: string | JSX.Element;
  items: CardItem[];
};

export default function StructuredCard({ label, items, ...rest }: StructuredCardProps) {
  return (
    <Card {...rest}>
      {label ? <div className="mb-4 text-[#333] text-base font-bold">{label}</div> : null}

      {items && items.length ? (
        items.map((x, i) => {
          if (x.value !== null && x.value !== undefined) {
            return (
              <FieldGroup
                key={i}
                label={x.label}
                value={
                  <>
                    <div>{x.value}</div>
                    {x.hint && <div className="text-xs text-gray-label">{x.hint}</div>}
                  </>
                }
              />
            );
          }

          return null;
        })
      ) : (
        <div className="mb-4">
          <div className="text-sm text-black break-words whitespace-pre-line">No policy coverages available</div>
        </div>
      )}
    </Card>
  );
}

export function TwoColumnStructuredCard({ label, items, ...rest }: StructuredCardProps) {
  return (
    <Card {...rest}>
      {label ? <div className="mb-4 text-[#333] text-base font-bold">{label}</div> : null}
      <div className="max-w-md">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {items.map(x => (
            <div className="mb-4" key={x.label}>
              <div className="text-xs text-gray-label break-words">{x.label}</div>
              <div className="text-sm text-black break-words whitespace-pre-line" data-cy-value={x.label}>
                {x.value}
              </div>
              {x.hint && <div className="text-xs text-gray-label">{x.hint}</div>}
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
}
