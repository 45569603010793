import * as React from 'react';

import classNames from 'classnames';

export default function Card({ children, className, ...rest }: React.ComponentProps<'div'>) {
  return (
    <div className={classNames('w-full border border-gray-200 p-6 bg-white', className)} {...rest}>
      {children}
    </div>
  );
}
