import { applyMiddleware, createStore } from 'redux';
import rootReducer, { history } from './modules/rootReducer';

import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import sagas from './modules/rootSagas';

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
  createLogger({
    collapsed: true,
  }),
];

const store = createStore(rootReducer, applyMiddleware(...middleware));

sagaMiddleware.run(sagas);

export default store;
export const dispatch = store.dispatch;
